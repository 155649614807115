import { observable } from "mobx";
import PlayerCharacterData from "./PlayerCharacterData";
import ModSets from "./ModSets";
import StatIds, { statIdToShortString, statIdToString } from "./StatIds";
import { CurrencyQuantity } from './Currency';
import { ModCalculator } from "../utils/mod-calculator";
import { ModLevelUp } from "../service/ModAPI";

export type ModSorting = StatIds | "Tier" | "Set" | "Slot" | "Level" | "Reroll Count" | "Speed Remainder";

const modLevelCosts = [0, 0, 3450, 6900, 10300, 13800, 18400, 24100, 29900, 37900, 48300, 58600, 86200, 121900, 157500, 248400];

export class ModStat
{
    updaterId: string;
    statId: StatIds;
    statValueDecimal: number;
    statValueUnscaled: number;
    //roll: string[]; currently ignored, unknown purpose?
    statRolls: number;
    unscaledRollValue: number[];
    statRollerBoundsMax: number;
    statRollerBoundsMin: number;

    public get formattedValue(): string
    {
        return ModStat.formatStatValue(this, this.statValueDecimal);
    }

    public get formattedShortValue(): string
    {
        return ModStat.formatStatValue(this, this.statValueDecimal, true, true);
    }

    public static formatStatValue(stat: ModStat, statValueDecimal: number, includeStatName: boolean = true, shortName: boolean = false): string
    {
        let nameLabel: string = includeStatName ? " " + (shortName ? statIdToShortString(stat.statId) : statIdToString(stat.statId)) : "";
        if (stat.statId === 1 || stat.statId === 5 || stat.statId === 28 || stat.statId === 41 || stat.statId === 42)
            return Math.trunc(statValueDecimal / 10000) + nameLabel;
        else
            return (statValueDecimal / 100).toFixed(1) + "%" + nameLabel;
    }

    public get rawValue(): number
    {
        if (this.statId === 1 || this.statId === 5 || this.statId === 28 || this.statId === 41 || this.statId === 42)
            return Math.trunc(this.statValueDecimal / 10000);
        else
            return this.statValueDecimal / 100;
    }

    public get imgUrl(): string
    {
        switch(this.statId)
        {
            case StatIds.Speed:
                return "https://api.hotutils.com/images/secondaries/speed.png";
            case StatIds.CriticalChance:
                return "https://api.hotutils.com/images/secondaries/critchance.png";
            case StatIds.Defense:
                return "https://api.hotutils.com/images/secondaries/defense.png";
            case StatIds.DefensePct:
                return "https://api.hotutils.com/images/secondaries/defense.png";
            case StatIds.Health:
                return "https://api.hotutils.com/images/secondaries/health.png";
            case StatIds.HealthPct:
                return "https://api.hotutils.com/images/secondaries/health.png";
            case StatIds.Offense:
                return "https://api.hotutils.com/images/secondaries/offense.png";
            case StatIds.OffensePct:
                return "https://api.hotutils.com/images/secondaries/offense.png";
            case StatIds.Potency:
                return "https://api.hotutils.com/images/secondaries/potency.png";
            case StatIds.Protection:
                return "https://api.hotutils.com/images/secondaries/protection.png";
            case StatIds.ProtectionPct:
                return "https://api.hotutils.com/images/secondaries/protection.png";
            case StatIds.Tenacity:
                return "https://api.hotutils.com/images/secondaries/tenacity.png";
        }
        return "https://api.hotutils.com/images/secondaries/speed.png";
    }

    public static CRIT_SECONDARY_ROLL_MAX = 2.25;
    public static CRIT_SECONDARY_ROLL_MIN = 1.12;

    public static DEFENSE_SECONDARY_ROLL_MAX = 2.25;
    public static DEFENSE_SECONDARY_ROLL_MIN = 1.12;

    public static DEFENSE_PCT_SECONDARY_ROLL_MAX = 2.25;
    public static DEFENSE_PCT_SECONDARY_ROLL_MIN = 1.12;

    public static HEALTH_SECONDARY_ROLL_MAX = 2.25;
    public static HEALTH_SECONDARY_ROLL_MIN = 1.12;

    public static HEALTH_PCT_SECONDARY_ROLL_MAX = 2.25;
    public static HEALTH_PCT_SECONDARY_ROLL_MIN = 1.12;

    public static OFFENSE_SECONDARY_ROLL_MAX = 2.25;
    public static OFFENSE_SECONDARY_ROLL_MIN = 1.12;

    public static OFFENSE_PCT_SECONDARY_ROLL_MAX = 2.25;
    public static OFFENSE_PCT_SECONDARY_ROLL_MIN = 1.12;

    public static PROTECTION_SECONDARY_ROLL_MAX = 2.25;
    public static PROTECTION_SECONDARY_ROLL_MIN = 1.12;

    public static PROTECTION_PCT_SECONDARY_ROLL_MAX = 2.25;
    public static PROTECTION_PCT_SECONDARY_ROLL_MIN = 1.12;

    private statMin(rarity: number): number
    {
        switch (this.statId)
        {
            case StatIds.Speed:
                return rarity === 6 ? 3 * this.statRolls + 1 : 3 * this.statRolls;
            case StatIds.CriticalChance:
                return rarity === 6 ? 1.12 * this.statRolls * 1.04 : 1.12 * this.statRolls;
            case StatIds.Defense:
                return rarity === 6 ? 4 * this.statRolls * 1.63 : 4 * this.statRolls;
            case StatIds.DefensePct:
                return rarity === 6 ? 0.85 * this.statRolls * 2.34 : 0.85 * this.statRolls;
            case StatIds.Health:
                return rarity === 6 ? 214 * this.statRolls * 1.26 : 214 * this.statRolls;
            case StatIds.HealthPct:
                return rarity === 6 ? 0.56 * this.statRolls * 1.86 : 0.56 * this.statRolls;
            case StatIds.Offense:
                return rarity === 6 ? 23 * this.statRolls * 1.10 : 23 * this.statRolls;
            case StatIds.OffensePct:
                return rarity === 6 ? 0.2 * this.statRolls * 3.02 : 0.2 * this.statRolls;
            case StatIds.Potency:
                return rarity === 6 ? 1.12 * this.statRolls * 1.33 : 1.12 * this.statRolls;
            case StatIds.Protection:
                return rarity === 6 ? 415 * this.statRolls * 1.11 : 415 * this.statRolls;
            case StatIds.ProtectionPct:
                return rarity === 6 ? 1.12 * this.statRolls * 1.33 : 1.12 * this.statRolls;
            case StatIds.Tenacity:
                return rarity === 6 ? 1.12 * this.statRolls * 1.33 : 1.12 * this.statRolls;
            default:
                return 0;
        }
    }

    private statMax(rarity: number): number
    {
        switch (this.statId)
        {
            case StatIds.Speed:
                return rarity === 6 ? 6 * this.statRolls + 1 : 6 * this.statRolls;
            case StatIds.CriticalChance:
                return rarity === 6 ? 2.25 * this.statRolls * 1.04 : 2.25 * this.statRolls;
            case StatIds.Defense:
                return rarity === 6 ? 9 * this.statRolls * 1.63 : 9 * this.statRolls;
            case StatIds.DefensePct:
                return rarity === 6 ? 1.7 * this.statRolls * 2.34 : 1.7 * this.statRolls;
            case StatIds.Health:
                return rarity === 6 ? 428 * this.statRolls * 1.26 : 428 * this.statRolls;
            case StatIds.HealthPct:
                return rarity === 6 ? 1.13 * this.statRolls * 1.86 : 1.13 * this.statRolls;
            case StatIds.Offense:
                return rarity === 6 ? 46 * this.statRolls * 1.10 : 46 * this.statRolls;
            case StatIds.OffensePct:
                return rarity === 6 ? 0.56 * this.statRolls * 3.02 : 0.56 * this.statRolls;
            case StatIds.Potency:
                return rarity === 6 ? 2.25 * this.statRolls * 1.33 : 2.25 * this.statRolls;
            case StatIds.Protection:
                return rarity === 6 ? 830 * this.statRolls * 1.11 : 830 * this.statRolls;
            case StatIds.ProtectionPct:
                return rarity === 6 ? 2.33 * this.statRolls * 1.33 : 2.33 * this.statRolls;
            case StatIds.Tenacity:
                return rarity === 6 ? 2.25 * this.statRolls * 1.33 : 2.25 * this.statRolls;
            default:
                return 0;
        }
    }

    public rollQuality(rarity: number): number
    {
        return (this.rawValue - this.statMin(rarity)) /
            ((this.statMax(rarity) - this.statMin(rarity)));
    }

    public percentOfMax(rarity: number): number
    {
        switch (this.statId)
        {
            case StatIds.CriticalChance:
                return rarity === 6 ? this.rawValue / 11.7 : this.rawValue / 11.25;
            case StatIds.Defense:
                return rarity === 6 ? this.rawValue / 73 : this.rawValue / 45;
            case StatIds.DefensePct:
                return rarity === 6 ? this.rawValue / 19.89 : this.rawValue / 8.5;
            case StatIds.Health:
                return rarity === 6 ? this.rawValue / 2696 : this.rawValue / 2140;
            case StatIds.HealthPct:
                return rarity === 6 ? this.rawValue / 10.51 : this.rawValue / 5.65;
            case StatIds.Offense:
                return rarity === 6 ? this.rawValue / 253 : this.rawValue / 230;
            case StatIds.OffensePct:
                return rarity === 6 ? this.rawValue / 8.46 : this.rawValue / 2.8;
            case StatIds.Potency:
                return rarity === 6 ? this.rawValue / 14.96 : this.rawValue / 11.25;
            case StatIds.Protection:
                return rarity === 6 ? this.rawValue / 4607 : this.rawValue / 4150;
            case StatIds.ProtectionPct:
                return rarity === 6 ? this.rawValue / 15.49 : this.rawValue / 11.65;
            case StatIds.Speed:
                return rarity === 6 ? this.rawValue / 31 : this.rawValue / 30;
            case StatIds.Tenacity:
                return rarity === 6 ? this.rawValue / 14.96 : this.rawValue / 11.25;
            default:
                return 0;
        }
    }

    public percentOfMaxByTier(rarity: number, tier: number): number
    {
        switch (this.statId)
        {
            case StatIds.CriticalChance:
                return rarity === 6 ? this.rawValue / 11.7 : this.rawValue / (2.25 * tier);
            case StatIds.Defense:
                return rarity === 6 ? this.rawValue / 73 : this.rawValue / (9 * tier);
            case StatIds.DefensePct:
                return rarity === 6 ? this.rawValue / 19.89 : this.rawValue / (1.7 * tier);
            case StatIds.Health:
                return rarity === 6 ? this.rawValue / 2696 : this.rawValue / (428 * tier);
            case StatIds.HealthPct:
                return rarity === 6 ? this.rawValue / 10.51 : this.rawValue / (1.13 * tier);
            case StatIds.Offense:
                return rarity === 6 ? this.rawValue / 253 : this.rawValue / (46 * tier);
            case StatIds.OffensePct:
                return rarity === 6 ? this.rawValue / 8.46 : this.rawValue / (0.56 * tier);
            case StatIds.Potency:
                return rarity === 6 ? this.rawValue / 14.96 : this.rawValue / (2.25 * tier);
            case StatIds.Protection:
                return rarity === 6 ? this.rawValue / 4607 : this.rawValue / (830 * tier);
            case StatIds.ProtectionPct:
                return rarity === 6 ? this.rawValue / 15.49 : this.rawValue / (2.33 * tier);
            case StatIds.Speed:
                return rarity === 6 ? this.rawValue / 31 : this.rawValue / (6 * tier);
            case StatIds.Tenacity:
                return rarity === 6 ? this.rawValue / 14.96 : this.rawValue / (2.25 * tier);
            default:
                return 0;
        }
    }

    constructor(json: any, clone: ModStat | null = null)
    {
        if (clone === null)
        {
            this.updaterId = json.updaterId;
            this.statId = json.stat.unitStatId;
            this.statValueDecimal = json.stat.statValueDecimal;
            this.statValueUnscaled = json.stat.unscaledDecimalValue;
            this.statRolls = json.statRolls;
            this.unscaledRollValue = json.unscaledRollValue;
            this.statRollerBoundsMax = json.statRollerBoundsMax;
            this.statRollerBoundsMin = json.statRollerBoundsMin;
        } else
        {
            this.updaterId = clone.updaterId;
            this.statId = clone.statId;
            this.statValueDecimal = clone.statValueDecimal;
            this.statValueUnscaled = clone.statValueUnscaled;
            this.statRolls = clone.statRolls;
            this.unscaledRollValue = clone.unscaledRollValue;
            this.statRollerBoundsMax = clone.statRollerBoundsMax;
            this.statRollerBoundsMin = clone.statRollerBoundsMin;
        }
    }
}

export class ModData
{
    @observable id: string = "";
    @observable rarity: number = 1;
    @observable level: number = 1;
    @observable slot: number = 1;
    @observable set: ModSets;
    @observable tier: number = 1;
    @observable locked: boolean = false;
    @observable inLoadout: boolean = false;
    @observable inGameLoadouts: string[] | null = null;
    @observable primary: ModStat;
    @observable secondaries: ModStat[];
    @observable equippedCharacter: PlayerCharacterData | null = null;
    @observable state: "Desired" | "Incomplete" | "Unmatched" | "Garbage" = "Incomplete";
    @observable sellValue: CurrencyQuantity | null = null;
    @observable levelCost: CurrencyQuantity | null = null;
    @observable removeCost: CurrencyQuantity | null = null;
    @observable rerolledCount : number = 0;

    @observable slicedMod: ModData | null = null;

    constructor(json: any, characterMap: Map<string, PlayerCharacterData> | null, slice: boolean = false)
    {
        this.id = json.id;
        this.rarity = json.rarity;
        this.level = json.level;
        this.slot = json.slot - 1; // cg has mods from slot 2-7 like crazy people
        this.set = Number(json.setId);
        this.tier = json.tier;
        this.locked = json.locked;
        this.rerolledCount = json.rerolledCount || 0;
        if (json.sellValue) this.sellValue = new CurrencyQuantity(json.sellValue);
        if (json.levelCost) this.levelCost = new CurrencyQuantity(json.levelCost);
        if (json.removeCost) this.removeCost = new CurrencyQuantity(json.removeCost);
        if (json.inLoadout)
        {
            // todo: track which loadout it is in?
            this.inLoadout = true;
        }
        if (json.loadouts)
        {
            this.inGameLoadouts = json.loadouts.map((l: any) => "Tab: " + l.tabName + " Loadout: " + l.loadoutName);
        }
        this.primary = new ModStat(json.primaryStat);
        this.secondaries = json.secondaryStat.map((s: any) => new ModStat(s));
        if (json.unit != null && characterMap !== null)
        {
            this.equippedCharacter = characterMap.get(json.unit.baseId)!;
        }

        if (json.rarity === 5 && slice === false)
        {
            this.slicedMod = new ModData(json, characterMap, true);
            ModCalculator.sliceMod(this.slicedMod);
        }
    }

    public get okToSlice(): boolean
    {
        if (this.level < 15 || this.rarity < 5)
            return false;

        if (this.equippedCharacter !== null && this.equippedCharacter.gearLevel < 12 && this.rarity === 5 && this.tier === 5)
            return false;

        if (this.rarity === 6 && this.tier === 5)
            return false;

        return true;
    }

    public get okToSell(): boolean
    {
        if (this.rarity > 5)
            return false;

        let sec = this.secondaryOfStat(StatIds.Speed);
        if (sec && sec.rawValue >= 20)
            return false;

        return true;
    }

    public okToCalibrate(attenuators: number): boolean
    {
        if (this.rarity < 5)
            return false;

        if (this.rerolledCount >= (this.tier + 1))
            return false;
        
        switch (this.rerolledCount)
        {
            case 0:
                return attenuators >= 15;
            case 1:
                return attenuators >= 25;
            case 2:
                return attenuators >= 40;
            case 3:
                return attenuators >= 75;
            case 4:
                return attenuators >= 100;
            case 5:
                return attenuators >= 150;
            default:
                return false;
        }
    }

    public get allSecondaryStats(): StatIds[]
    {
        return this.secondaries.map((s) => s.statId);
    }

    public get tierLetter(): string
    {
        switch (this.tier)
        {
            case 1:
                return "E";
            case 2:
                return "D";
            case 3:
                return "C";
            case 4:
                return "B";
            case 5:
                return "A";
            default:
                return "";
        }
    }

    public get speedRemainder(): number
    {
        let stat = this.secondaryOfStat(StatIds.Speed);
        if (!stat)
            return 0;
        return stat.unscaledRollValue.reduce((total, v) => total + v, 0) / 100000.0 - stat.statValueDecimal / 10000.0 + (this.rarity === 6 ? 1 : 0);
    }

    public secondaryOfStat(stat: StatIds): ModStat | null
    {
        let matches = this.secondaries.filter((s) => s.statId === stat);
        if (matches.length === 0)
            return null;
        else
            return matches[0];
    }

    public getStatAmount(stat: StatIds): number
    {
        if (this.primary.statId === stat)
        {
            return this.primary.statValueUnscaled;
        }
        return this.getSecondaryStatAmount(stat);
    }

    public getSecondaryStatAmount(stat: StatIds): number
    {
        let secondary = this.secondaries.find(secondary => secondary.statId === stat);
        if (secondary !== undefined)
        {
            return secondary.statValueUnscaled;
        }
        return 0;
    }

    public rollQuality(): number
    {
        let quality: number = 0;
        let rolls: number = 0;

        this.secondaries.forEach(s =>
        {
            quality = quality + s.rollQuality(this.rarity) * s.statRolls;
            rolls = rolls + s.statRolls;
        });

        return quality / rolls;
    }

    public costToLevel(level: ModLevelUp): number
    {
        if (this.rarity === 6 || this.level === 15)
            return 0;
        let targetLevel = 0;
        switch (level)
        {
            case ModLevelUp.Expose:
                if (this.tier === 1)
                    targetLevel = 12;
                else if (this.tier === 2)
                    targetLevel = 9;
                else if (this.tier === 3)
                    targetLevel = 6;
                else if (this.tier === 4)
                    targetLevel = 3;
                break;
            case ModLevelUp.OneTier:
                targetLevel = Math.min(15, (Math.trunc(this.level / 3) + 1) * 3);
                break;
            case ModLevelUp.SingleLevel:
                targetLevel = Math.min(15, this.level + 1);
                break;
            case ModLevelUp.To12:
                targetLevel = 12;
                break;
            case ModLevelUp.ToMax:
                targetLevel = 15;
                break;
        }
        if (targetLevel === 0 || targetLevel < this.level)
            return 0;
        return modLevelCosts[targetLevel] - modLevelCosts[this.level];
    }

    public get isIncomplete(): boolean
    {
        return (this.tier === 1 && this.level < 12) || (this.tier === 2 && this.level < 9) || (this.tier === 3 && this.level < 6) || (this.tier === 4 && this.level < 3);
    }

    public get costToSlice(): Map<number, number>
    {
        if (this.rarity < 5)
        {
            return new Map();
        }
        let ret: Map<number, number> = new Map();
        if (this.rarity === 5)
        {
            if (this.tier === 1)
            {
                ret.set(0, 10);
            }
            else if (this.tier === 2)
            {
                ret.set(0, 5);
                ret.set(1, 15);
            }
            else if (this.tier === 3)
            {
                ret.set(1, 10);
                ret.set(2, 25);
            }
            else if (this.tier === 4)
            {
                ret.set(2, 15);
                ret.set(3, 35);
            }
            else if (this.tier === 5)
            {
                ret.set(4, 50);
                ret.set(5, 50);
                ret.set(6, 20);
            }
        }
        else if (this.rarity === 6)
        {
            if (this.tier === 1)
            {
                ret.set(7, 10);
            }
            else if (this.tier === 2)
            {
                ret.set(7, 10);
                ret.set(8, 10);
                ret.set(4, 5);
                ret.set(5, 5);
            }
            else if (this.tier === 3)
            {
                ret.set(7, 10);
                ret.set(8, 20);
                ret.set(9, 10);
                ret.set(4, 10);
                ret.set(5, 10);
            }
            else if (this.tier === 4)
            {
                ret.set(5, 30);
                ret.set(6, 10);
                ret.set(8, 20);
                ret.set(9, 20);
                ret.set(10, 15);
            }
        }
        return ret;
    }

    public static getSortFunc(byStat: ModSorting, ascending: boolean): (a: ModData, b: ModData) => number
    {
        let mult = ascending ? -1 : 1;
        return (a: ModData, b: ModData) =>
        {
            switch (byStat)
            {
                case "Tier":
                    return a.tier < b.tier ? 1 * mult : (a.tier > b.tier ? -1 * mult : 0);
                case "Set":
                    return a.set < b.set ? 1 * mult : (a.set > b.set ? -1 * mult : 0);
                case "Slot":
                    return a.slot < b.slot ? 1 * mult : (a.slot > b.slot ? -1 * mult : 0);
                case "Level":
                    return a.level < b.level ? 1 * mult : (a.level > b.level ? -1 * mult : 0);
                case "Reroll Count":
                    return a.rerolledCount < b.rerolledCount ? 1 * mult : (a.rerolledCount > b.rerolledCount ? -1 * mult : 0);
                case "Speed Remainder":
                    return a.speedRemainder < b.speedRemainder ? 1 * mult : (a.speedRemainder > b.speedRemainder ? -1 * mult : 0);
                default:
                    let aStat: ModStat | null;
                    let bStat: ModStat | null;
                    if (a.primary.statId === byStat)
                    {
                        aStat = a.primary;
                    }
                    else
                    {
                        aStat = a.secondaryOfStat(byStat);
                    }
                    if (b.primary.statId === byStat)
                    {
                        bStat = b.primary;
                    }
                    else
                    {
                        bStat = b.secondaryOfStat(byStat);
                    }
                    if (aStat && bStat)
                    {
                        if (aStat.rawValue < bStat.rawValue)
                            return 1 * mult;
                        else if (aStat.rawValue > bStat.rawValue)
                            return -1 * mult;
                        else
                            return 0;
                    }
                    else if (aStat)
                    {
                        return -1 * mult;
                    }
                    else if (bStat)
                    {
                        return 1 * mult;
                    }
                    return 0;
            }
        }
    }
}

export default ModData;
