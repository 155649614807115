import {IGenericTemplate} from "../components/Cards/Cards";
import {ITBPlatoonFilterPlayerUnits, TBRoteZoneData} from "./TerritoryBattleData";
import roteData from "./gen_tb.json";

export interface IROTEPlatoonTemplate extends IGenericTemplate {
	createdBy: string;
	editedBy: string;
	showSpecialCharacters: boolean;
	finishPlatoonsMaxHour: number;
	isSortingByCharacterName: boolean;
	isActive: boolean;
	filters: ITBPlatoonFilterPlayerUnits;
	isTopCollapsed: boolean;
	isMiddleCollapsed: boolean;
	isBottomCollapsed: boolean;
	isBonusCollapsed: boolean;
	zones: ITBRoteZones;
}

export type IROTEZoneType = 'top' | 'middle' | 'bottom' | "bonus";


export const extraPlanets = ['zeffo', 'mandalore'];

export type TBRoteLocationZoneList = {
	[K in IROTEZoneType]: TBRoteZoneData[];
};

export interface ITBRoteZones {
	top?: ITBRoteZoneData;
	middle?: ITBRoteZoneData;
	bottom?: ITBRoteZoneData;
	bonus?: ITBRoteZoneData;
	allZones?: ITBRoteZoneData[];
}

export interface ITBRotePlanetsData {
	zoneId: string;
	forceAlignment: number;
	planetName: string;
	requiredRelicLevel: number;
	requiredStars: number;
}

export interface ITBRoteZoneData extends ITBRotePlanetsData{
	platoons: IROTETBPlatoonsZoneUnit[];
}

export interface ITBRoteZoneLocationData extends ITBRotePlanetsData{
	location: IROTEZoneType;
}

export interface IROTETBPlatoonsZoneUnit {
	id: string;
	state: boolean;
	units: IROTETBPlatoonsMembersZoneUnit[];
}

export interface IROTEUnitInfo {
	zoneId: string;
	position: IROTEZoneType;
	platoonId: string;
	planetName: string;
	baseId: string;
	allyCode?: number;
	playerName?: string;
	discordTag?: string;
	isFilled?: boolean;
}

export interface IROTETBPlatoonsMembersZoneUnit {
	baseId: string;
	characterName: string;
	faction: string;
	alignment: number;
	isCapitalShip: boolean;
	player?: string;
	allyCode?: number;
	discordTag?: string;
	isFilled?: boolean;
	requiredRelicLevel?: number;
	requiredStars?: number;
}

export interface IFilledROTETBPlatoonsMembersZoneUnit extends IROTETBPlatoonsMembersZoneUnit {
	filledZoneUnit? : IROTEUnitInfo;
}


export interface IDuplicateUnitsSamePlayer {
	allyCode: number;
	baseId: string;
	playerName?: string;
}

export function newZone(zone: string) {
	switch (zone) {
		case "top":
			return 'bottom';
		case "middle":
			return 'top';
		case "bottom":
			return 'middle';
		default:
			return 'bonus';
	}
}

export function newZoneName(zone: string) {
	switch (zone) {
		case "top":
			return 'bottom - LS';
		case "middle":
			return 'top - DS';
		case "bottom":
			return 'middle - Mixed';
		default:
			return 'bonus';
	}
}

export function convertToOperation(word: string): number {
	const lastLetter = word.charAt(word.length - 1);
	const operationNumber = parseInt(lastLetter, 10);

	if (!isNaN(operationNumber)) {
		return operationNumber;
	} else {
		return 0; // If the last letter is not a number, return the original word.
	}
}

export function getAllZones(template: IROTEPlatoonTemplate): ITBRoteZoneData[]
{
	let ret: ITBRoteZoneData[] = template.zones.allZones?.slice() ?? [];
	if (template.zones.top)
		ret.push(template.zones.top);
	if (template.zones.middle)
		ret.push(template.zones.middle);
	if (template.zones.bottom)
		ret.push(template.zones.bottom);
	if (template.zones.bonus)
		ret.push(template.zones.bonus);

	return ret;
}

export function zoneIdToLocation(zoneId: string): IROTEZoneType | undefined
{
	let conflictZone = roteData["t05D"].conflictZoneDefinition.find(z => z.zoneDefinition.zoneId === zoneId);
	if (!conflictZone)
		return undefined;

	if (conflictZone.isBonus)
		return "bonus";
	else if (conflictZone.forceAlignment === 1)
		return "middle";
	else if (conflictZone.forceAlignment === 2)
		return "bottom";
	else if (conflictZone.forceAlignment === 3)
		return "top";
	else
		return undefined;
}