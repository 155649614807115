import React from "react";
import { BasicLayout } from '../../../layouts/BasicLayout';
import IPageProps from "../../IPageProps";
import { observer } from "mobx-react";
import styled from 'styled-components';
import { Alert, Button, Checkbox, Modal, RadioChangeEvent, Spin, Steps } from 'antd';
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { Radio, Input, Space } from 'antd';
import styles from "./styles/ModWizard.module.scss";
import { createDefaultStatTarget, LD_ASSAULT_CAT, LD_CRANCOR_CAT, LD_KRAYT_CAT, LD_DSTB_CAT, LD_FVF_CAT, LD_HSTR_CAT, LD_JOURNEY_CAT, LD_LSTB_CAT, LD_ROTE_CAT, LD_TVT_CAT, LoadoutDefinition, LoadoutDefinitionTarget, LoadoutDefintionSummary, MAX_LD_SQUAD_SIZE, LD_NABOO_CAT } from "../../../model/LoadoutDefinition";
import ModWizardSquadManager from "./ModWizardSquadManager";
import ModAPI from "../../../service/ModAPI";
import ModWizardAutomate from "./ModWizardAutomate";
import { CalculationResultSummary, getCalculationResultsSummary, ModAutomationCalculatorInput, ModAutomationCalculatorOutput, ModAutomationCalculatorUnitInput, ModAutomationSettings, RelatedUnit } from "../PlaygroundBeta/PlaygroundCalculator/ModAutomation";
import { PlaygroundController } from "../PlaygroundBeta/PlaygroundController";
import PlayerData from "../../../model/PlayerData";
import { PlaygroundPlayerSettings } from "../PlaygroundBeta/PlaygroundData";
import { DisplaySettings, MediaSizes } from "../../../utils/media-sizes";
import BaseAPI from "../../../service/BaseAPI";
import { IGuildSquadTemplate } from "../../../model/Squads";
import PrioritizationAPI, { IPrioritizationInput, PrioritizationAlignmentEnum, PrioritizationSortTypeEnum } from "../../../service/PrioritizationAPI";
import ModWizardTarget from "./ModWizardTarget";
import { ModEquip } from "../../../components/swgoh/ModEquip/ModEquip";
import { ImageOverride } from "../../../model/ImageOverride";
import { ModWizardController } from "./ModWizardController";

const { Step } = Steps;

const ModWizardContainer = styled.div`
    height: 100%;
 
`;


export enum GoalTypeEnum
{
    FiveVsFive = 1,
    ThreeVsThree,
    LSTB,
    DSTB,
    HSTR,
    CRancor,
    Krayt,
    Naboo,
    Journey,
    AssaultBattle,
    ROTE
}


export enum GoalAccountEnum
{
    Self = 1,
    Ally
}

export enum ReviewViewModeEnum
{
    Summary = 1,
    Portrait
}

interface SquadImages
{
    description: string;
    url: string;
}

export class ModeSettings
{
    mas: ModAutomationSettings;
    loadoutDefinitionFilters: string[];
    squadFilters: string[];
    maxSize: number;
    entireRoster: boolean;
    prioritizationInput: IPrioritizationInput;

    constructor(mas: ModAutomationSettings, entireRoster: boolean, maxSize: number, loadoutDefinitionFilters: string[], squadFilters: string[],
        prioritizationInput: IPrioritizationInput)
    {
        this.mas = mas;
        this.loadoutDefinitionFilters = loadoutDefinitionFilters;
        this.squadFilters = squadFilters;
        this.maxSize = maxSize;
        this.entireRoster = entireRoster;
        this.prioritizationInput = prioritizationInput
    }
}

export class SquadSelection
{
    @observable requiredUnits: string[] = [];
    @observable optionalUnits: string[] = [];
    @observable selectedOptionalUnits: string[] = [];
    @observable unavailableUnits: string[] = [];

    @observable loadoutDefinitionSummary: LoadoutDefintionSummary | undefined = undefined;
}


@observer
class ModWizard extends React.Component<IPageProps>
{

    @observable currentStep: number = 0;
    @observable goal: GoalTypeEnum | undefined = undefined;
    @observable goalAccount: GoalAccountEnum = GoalAccountEnum.Self;

    @observable squadSelections: SquadSelection[] = [];

    @observable loadoutDescriptionBaseName: string | undefined = undefined;
    @observable loadoutDescriptionAuthor: string | undefined = undefined;

    @observable modeSettings: Map<GoalTypeEnum, ModeSettings> = new Map();

    @observable defaultMas: ModAutomationSettings = new ModAutomationSettings({});

    @observable loadoutDefinitions: LoadoutDefinition[] = [];
    @observable defaultTargets: Map<string, LoadoutDefinitionTarget> = new Map();

    @observable loadoutDefinitionsFetched: boolean = false;
    @observable defaultTargetsFetched: boolean = false;

    @observable calculationSummary: CalculationResultSummary[] = [];
    @observable calculatorOutput: ModAutomationCalculatorOutput | null = null;

    @observable reviewViewModeEnum: ReviewViewModeEnum = ReviewViewModeEnum.Portrait;
    @observable tinyScreen: boolean = false;
    @observable showStatDetails: boolean = false;

    @observable createBackup: boolean = true;
    @observable generateLoadout: boolean = true;
    @observable pushToGame: boolean = false;
    @observable generateImage: boolean = false;
    @observable confirmChanges: boolean = false;

    @observable planExecuted: boolean = false;

    @observable backupLoadoutDefinitionName: string = "";
    @observable createLoadoutDefinitionName: string = "";

    @observable priorityOrderSettings: IPrioritizationInput | undefined = undefined;

    @observable prioritization: string[] = [];

    private _retry = 0;
    @observable playerSquadTemplates: IGuildSquadTemplate[] | null = null;
    @observable errorPlayerSquadFetch?: string = undefined;

    @observable allyCode: string = "";

    @observable loadedAllies: Map<number, PlayerData> = new Map();

    @observable loadoutDefinitionSummaryBase: LoadoutDefintionSummary | undefined = undefined;

    @observable failedPushToGame: boolean = false;

    @observable imageUrls: SquadImages[] = [];

    @observable showSquadSelector: boolean = false;

    @observable displaySettings: DisplaySettings = new DisplaySettings();

    @observable includeUnitsNotInBase: boolean = true;

    @observable lockUnits: IObservableArray<string> = observable([]);
    @observable filterLockUnitText: string = '';

    @observable updating: boolean = false;

    steps = [
        {
            title: 'Goal',
            render: () => this.renderGoal(),
            ready: () => { return true; },
            skip: () => false,
            renderNextButton: () =>
            {
                return
            }
        },
        {
            title: 'Plan',
            ready: () =>
            {
                return this.goal !== undefined;
            },
            skip: () => false,
            render: () => this.renderPlan()
        },
        {
            title: 'Target',
            skip: () => { return this.fullRosterRemod() },
            ready: () => { return this.fullRosterRemod() || this.squadSelections.length > 0; },
            render: () => this.renderTarget()
        },
        {
            title: 'Lock Units',
            skip: () => false,
            ready: () =>
            {
                return this.goal !== undefined;
            },
            render: () => this.renderLockUnits()
        },
        {
            title: 'Automate',
            skip: () => false,
            ready: () =>
            {
                return this.goalAccount === GoalAccountEnum.Self || (this.isValidAllyCode() && this.getAllyIsLoaded())

            },
            render: () => this.renderAutomate()
        },
        {
            title: 'Use',
            skip: () => false,
            ready: () => { return this.calculatorOutput !== null; },
            render: () => this.renderUse()
        }
    ];


    @action
    componentDidMount()
    {
        const todayDate = new Date()
        let dateString = todayDate.toISOString().split('T')[0];

        this.backupLoadoutDefinitionName = "Baseline from Wizard " + dateString;

        var index = 1;
        while (this.backupDefinitionUsed() && index < 10)
        {
            this.backupLoadoutDefinitionName = this.backupLoadoutDefinitionName + " - " + index;
            index = index + 1;
        }

        let smallAutomation = new ModAutomationSettings({
            useBeta: true,
            attemptDesiredTargets: true
        });
        let bigAutomation = new ModAutomationSettings({
            useBeta: true,
            attemptDesiredTargets: true,
            dontBreakSets: true
        });

        const gacPrioritization: IPrioritizationInput = {
            allyCode: this.props.user.currentPlayer!.allyCode,
            sortType: PrioritizationSortTypeEnum.GAC,
            alignment: PrioritizationAlignmentEnum.ALL,
            minGear: undefined,
            gearThreshold: undefined,
            ignoreArena: true,
            limitTop: undefined,
            omicronConquest: false,
            omicronGac: true,
            omicronTb: false,
            omicronTw: true,
            omicronRaids: false
        }

        this.priorityOrderSettings = JSON.parse(JSON.stringify(gacPrioritization));

        this.modeSettings.set(GoalTypeEnum.LSTB, new ModeSettings(smallAutomation, false, 5, [LD_LSTB_CAT], ['TB - Light Side', 'TB LS - Phase 1', 'TB LS - Phase 2', 'TB LS - Phase 3', 'TB LS - Phase 4', 'TB LS - Phase 5', 'TB LS - Phase 6'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.ROTE, new ModeSettings(smallAutomation, false, 5, [LD_ROTE_CAT], ['TB ROTE', 'TB ROTE - Phase 1', 'TB ROTE - Phase 2', 'TB ROTE - Phase 3', 'TB ROTE - Phase 4', 'TB ROTE - Phase 5', 'TB ROTE - Phase 6'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.DSTB, new ModeSettings(smallAutomation, false, 5, [LD_DSTB_CAT], ['TB - Dark Side', 'TB DS - Phase 1', 'TB DS - Phase 2', 'TB DS - Phase 3', 'TB DS - Phase 4', 'TB DS - Phase 5', 'TB DS - Phase 6'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.Naboo, new ModeSettings(smallAutomation, false, 5, [LD_NABOO_CAT], ['Raids - Naboo'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.HSTR, new ModeSettings(smallAutomation, false, 5, [LD_HSTR_CAT], ['Raids - HSith'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.CRancor, new ModeSettings(smallAutomation, false, 5, [LD_CRANCOR_CAT], ['Raids - CPit'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.Krayt, new ModeSettings(smallAutomation, false, 5, [LD_KRAYT_CAT], ['Raids - Krayt'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.Journey, new ModeSettings(smallAutomation, false, 5, [LD_JOURNEY_CAT], ['Event'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.AssaultBattle, new ModeSettings(smallAutomation, false, 5, [LD_ASSAULT_CAT], ['Event'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.FiveVsFive, new ModeSettings(bigAutomation, true, 5, [LD_FVF_CAT], ['GAC', 'GAC - Defense', 'GAC - Offense', 'TW', 'TW - Offense', 'TW - Defense'],
            JSON.parse(JSON.stringify(gacPrioritization))));
        this.modeSettings.set(GoalTypeEnum.ThreeVsThree, new ModeSettings(bigAutomation, true, 3, [LD_TVT_CAT], ['3v3', '3v3 - Defense', '3v3 - Offense'],
            JSON.parse(JSON.stringify(gacPrioritization))));



        window.addEventListener("resize", () => this.updateScreenSize());
        this.updateScreenSize();
        this.onUpdateLoadout();
        this.fetchLoadoutDefinitions();
        this.getDataPlayerSquadTemplate();
        this.fetchDefaultTargets();
        this.fetchPriorityOrder();
    }

    @computed get waitingOnFullLoadoutResults(): boolean
    {
        if (this.props.user.currentPlayer !== null)
        {
            if (this.props.user.currentPlayer.loadoutsInitialized === false ||
                (this.props.user.currentPlayer.modLoadouts.length > 0 && this.props.user.currentPlayer.modLoadouts[0].testResult === null))
            {
                return true;
            }
        }
        return false;
    }


    @action
    async loadFullModSetData()
    {
        if (this.props.user.currentPlayer !== null)
        {
            await BaseAPI.fetchFullModSetData(this.props.user);
            this.forceUpdate();
        }
    }


    @action
    onUpdateLoadout()
    {
        if (this.props.user.hasGameConnection && this.props.user.currentPlayer !== null)
        {
            if (this.waitingOnFullLoadoutResults)
            {
                this.loadFullModSetData();
            }
        }
    }



    @action
    updateScreenSize()
    {
        this.tinyScreen = window.screen.width < MediaSizes.mobileL || window.screen.height < MediaSizes.mobileL;
        this.showStatDetails = window.screen.width > 1100
    }

    private getDataPlayerSquadTemplate()
    {
        this.fetchPlayerSquads().catch(() => this._retry = window.setTimeout(() => this.getDataPlayerSquadTemplate(), 2000));
    }

    private async fetchPlayerSquads(): Promise<void>
    {
        try
        {
            const playerSquadTemplates = await BaseAPI.getSquads(this.props.user);

            runInAction(() =>
            {
                this.playerSquadTemplates = playerSquadTemplates.filter((t) => t.shareType === 0 && !t.hasBeenShared) || [];
            });

        } catch (e: any)
        {
            runInAction(() => this.errorPlayerSquadFetch = e.errorMessage)
        }
    }


    private async fetchPriorityOrder()
    {
        let priorityResponse = await PrioritizationAPI.prioritizeRoster(this.priorityOrderSettings!);
        runInAction(() =>
        {
            this.prioritization = priorityResponse;
        });
    }


    changeSelection()
    {
        runInAction(() =>
        {
            this.squadSelections = [];
            this.calculationSummary = [];
            this.calculatorOutput = null;
            this.planExecuted = false;
            this.loadoutDefinitionSummaryBase = undefined;
        });
    }

    async fetchLoadoutDefinitions()
    {
        await ModAPI.fetchLoadoutDefintions(this.props.user);
        runInAction(() =>
        {
            this.loadoutDefinitionsFetched = true;
        });
    }


    async fetchDefaultTargets()
    {
        let targets = await PlaygroundController.getDefaultTargets(this.props.user);
        let userTargets = await PlaygroundController.getDefaultUserTargets(this.props.user);

        runInAction(() =>
        {
            this.props.gameData.units!.filter(u => u.combatType === 1).forEach(u =>
            {
                this.defaultTargets.set(u.baseId, createDefaultStatTarget(u));
            });
            targets.forEach(t =>
            {
                this.defaultTargets.set(t.unitBaseId, t);
            });
            userTargets.forEach(t =>
            {
                this.defaultTargets.set(t.unitBaseId, t);
            });
            this.defaultTargetsFetched = true;
        });
    }

    get loadoutDescription(): string
    {
        if (this.loadoutDescriptionBaseName !== undefined && this.loadoutDescriptionAuthor !== undefined)
        {
            return this.loadoutDescriptionBaseName + " [" + this.loadoutDescriptionAuthor + "]";
        }
        return "";
    }


    @action
    setGoal(e: RadioChangeEvent)
    {
        this.goal = e.target.value;

        switch (this.goal)
        {
            case GoalTypeEnum.AssaultBattle:
                this.createLoadoutDefinitionName = "Assault Battle from Wizard";
                break;
            case GoalTypeEnum.CRancor:
                this.createLoadoutDefinitionName = "Crancor from Wizard";
                break;
            case GoalTypeEnum.DSTB:
                this.createLoadoutDefinitionName = "DSTB from Wizard";
                break;
            case GoalTypeEnum.FiveVsFive:
                this.createLoadoutDefinitionName = "5v5 from Wizard";
                break;
            case GoalTypeEnum.HSTR:
                this.createLoadoutDefinitionName = "HSTR from Wizard";
                break;
            case GoalTypeEnum.Journey:
                this.createLoadoutDefinitionName = "Journey from Wizard";
                break;
            case GoalTypeEnum.LSTB:
                this.createLoadoutDefinitionName = "LSTB from Wizard";
                break;
            case GoalTypeEnum.ThreeVsThree:
                this.createLoadoutDefinitionName = "3v3 from Wizard";
                break;
            case GoalTypeEnum.Naboo:
                this.createLoadoutDefinitionName = "Naboo from Wizard";
                break;
        }
        this.changeSelection();
    }

    @action
    setGoalAccount(e: RadioChangeEvent)
    {
        this.goalAccount = e.target.value;
        this.changeSelection();
    }

    selectBase()
    {

    }

    calculateUnavailableUnits()
    {
        let unavailableUnits: string[] = [];

        this.squadSelections.forEach(ss =>
        {
            ss.unavailableUnits = unavailableUnits.slice(0);
            unavailableUnits = unavailableUnits.concat(ss.requiredUnits.filter(u => unavailableUnits.indexOf(u) === -1));
            unavailableUnits = unavailableUnits.concat(ss.selectedOptionalUnits.filter(u => unavailableUnits.indexOf(u) === -1));
        });

    }

    async onAddSquadSelection(ss: SquadSelection)
    {
        runInAction(() =>
        {
            this.squadSelections.push(ss);
            this.calculatorOutput = null;
            this.calculateUnavailableUnits();
            this.planExecuted = false;
        });

        if (ss.loadoutDefinitionSummary !== undefined)
        {
            await this.fetchLoadoutDefinition(ss.loadoutDefinitionSummary);
            runInAction(() =>
            {
                this.calculateUnavailableUnits();
            });
        }
        this.forceUpdate();
    }

    async fetchLoadoutDefinition(lds: LoadoutDefintionSummary): Promise<LoadoutDefinition | undefined>
    {
        let existingLoadoutDefinition = this.loadoutDefinitions.find(ld =>
            ld.title === lds.title && ld.discordTag === lds.discordTag);
        if (existingLoadoutDefinition === undefined)
        {
            let modal = Modal.info({ maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } }, title: "Please wait, fetching loadout definition", content: <Spin size="large" /> })
            try
            {
                let loadoutDefinition = await ModAPI.loadLoadoutDefinition(this.props.user, lds.title, lds.discordId);
                runInAction(() =>
                {
                    this.loadoutDefinitions.push(loadoutDefinition);
                });
                modal.destroy();
                return loadoutDefinition;
            }
            catch (response: any)
            {
                modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            }
        }
        return existingLoadoutDefinition;
    }

    missingDefintions()
    {
        return false;
    }

    fullRosterRemod()
    {
        return this.goal === undefined || this.modeSettings.has(this.goal) === false ? false : this.modeSettings.get(this.goal)!.entireRoster;
    }

    singleEventRemod()
    {
        return this.goal !== undefined && this.goal !== GoalTypeEnum.FiveVsFive && this.goal !== GoalTypeEnum.ThreeVsThree;
    }

    getGoalAccount(): GoalAccountEnum | undefined
    {
        if (this.singleEventRemod() === false)
        {
            return GoalAccountEnum.Self;
        }
        return this.goalAccount;
    }

    private getTrimmedAllyCode(): string
    {
        return this.allyCode.replace(/-/g, "").trim();
    }

    getSelectedPlayer(): PlayerData | undefined
    {
        return this.goalAccount === GoalAccountEnum.Self ? this.props.user.currentPlayer! :
            this.getTrimmedAllyCode() === "" ? undefined :
                this.loadedAllies.get(Number(this.getTrimmedAllyCode()));
    }

    createLoadoutDefinition(): LoadoutDefinition
    {
        let retVal = new LoadoutDefinition({});
        let playerUnits = this.getSelectedPlayer()!.characters;

        let ldsBase = this.loadoutDefinitionSummaryBase;

        let baseLoadoutDefinition = ldsBase === undefined ? undefined : this.loadoutDefinitions.find(ld =>
            ldsBase!.title === ld.title && ldsBase!.discordTag === ld.discordTag);

        this.squadSelections.forEach(ss =>
        {
            let loadoutDefinition = ss.loadoutDefinitionSummary === undefined ? undefined : this.loadoutDefinitions.find(ld =>
                ld.title === ss.loadoutDefinitionSummary!.title && ld.discordTag === ss.loadoutDefinitionSummary!.discordTag);

            let units = ss.requiredUnits.slice(0).concat(ss.selectedOptionalUnits);

            units.filter(unitId => playerUnits.has(unitId) && playerUnits.get(unitId)!.level >= 50).forEach(unitId =>
            {
                let target = this.defaultTargets.get(unitId)!;

                if (baseLoadoutDefinition !== undefined)
                {
                    let ldTarget = baseLoadoutDefinition.targets.find(t => t.unitBaseId === unitId);
                    if (ldTarget !== undefined)
                    {
                        target = ldTarget;
                    }
                }

                if (loadoutDefinition !== undefined)
                {
                    let ldTarget = loadoutDefinition.targets.find(t => t.unitBaseId === unitId);
                    if (ldTarget !== undefined)
                    {
                        target = ldTarget;
                    }
                }
                retVal.targets.push(target);
            });
        });

        if (this.fullRosterRemod())
        {
            let baseDefinitionUnits = (baseLoadoutDefinition === undefined || this.includeUnitsNotInBase) ? Array.from(playerUnits.values()) :
                Array.from(playerUnits.values()).filter(pu => baseLoadoutDefinition!.targets.find(t => t.unitBaseId === pu.baseId) !== undefined);

            baseDefinitionUnits.filter(unit =>
                unit.combatType === 1 && unit.level >= 50 && retVal.targets.find(t => t.unitBaseId === unit.baseId) === undefined).forEach(unit =>
                {
                    let target = this.defaultTargets.get(unit.baseId)!;
                    if (baseLoadoutDefinition !== undefined)
                    {
                        let bsd = baseLoadoutDefinition.targets.find(t => t.unitBaseId === unit.baseId);
                        if (bsd !== undefined)
                        {
                            target = bsd;
                        }
                    }

                    retVal.targets.push(target);
                });

            retVal.targets = retVal.targets.sort((t1, t2) =>
            {
                return this.prioritization.indexOf(t1.unitBaseId) - this.prioritization.indexOf(t2.unitBaseId);
            });
        }

        return retVal;
    }

    private async onLoadAlly()
    {
        let allyCodeNumber = Number(this.getTrimmedAllyCode());

        let newPlayer = await PlaygroundController.onLoadAlly(this.props.user, allyCodeNumber);
        if (newPlayer !== undefined)
        {
            runInAction(() =>
            {
                this.loadedAllies.set(allyCodeNumber, newPlayer!);
                this.forceUpdate();
            })
        }
    }

    private getAllyIsLoaded(): boolean
    {
        return this.isValidAllyCode() && this.loadedAllies.has(Number(this.getTrimmedAllyCode()));
    }
    private isValidAllyCode(): boolean
    {

        let trimmedAllyCode = this.getTrimmedAllyCode();
        let allyCodeNumber = Number(trimmedAllyCode);

        return trimmedAllyCode.length === 9 && isNaN(allyCodeNumber) === false;
    }

    @action
    onSaveLockedUnits()
    {
        this.updating = true;
        ModWizardController.onSetLockedUnits(this.props.user, this.lockUnits).then(() =>
        {

        }).catch((e) =>
        {
            console.error("Unable to update locker units: ", e);
        }).finally(() =>
        {
            runInAction(() =>
            {
                this.updating = false;
                this.forceUpdate();
            });
        });
        this.forceUpdate();
    }

    @action
    onRestoreLockedUnits()
    {
        ModWizardController.getLockedUnits(this.props.user).then((lu) =>
        {
            if (lu !== undefined)
            {
                runInAction(() =>
                {
                    this.lockUnits = observable(lu);
                });
            }

        }).finally(() =>
        {
            runInAction(() =>
            {
                this.updating = false;
            });
        });
    }

    renderLockUnits()
    {
        return <div>
            <div className={styles.paddedrow}>
                Select units to lock (these may be units that you like the current modding for)
            </div>

            <div className={styles.paddedrow}>
                {this.lockUnits.length} units currently selected.
            </div>

            <div className={styles.paddedrow}>
                <Button onClick={action(() => this.lockUnits = observable(this.props.gameData.units!.map(u => u.baseId)))}>Select All</Button>
                <Button onClick={action(() => this.lockUnits = observable([]))}>Clear</Button>
                <Button disabled={this.updating} onClick={() => this.onSaveLockedUnits()}>Save</Button>
                <Button disabled={this.updating} onClick={() => this.onRestoreLockedUnits()}>Restore</Button>
            </div>


            <div className={styles.paddedrow}>
                <Input value={this.filterLockUnitText} onChange={action((e) => this.filterLockUnitText = e.target.value)} />
            </div>


            <div className={styles.unitlockscroll}>
                {this.props.gameData.units!.filter(unit => this.filterLockUnitText.trim() === '' || unit.name.toUpperCase().indexOf(this.filterLockUnitText.toUpperCase()) !== -1).map(unit =>
                {
                    const unitChecked = this.lockUnits.includes(unit.baseId);
                    return <div>
                        <Checkbox defaultChecked={unitChecked} checked={unitChecked}
                            onChange={action((checked) =>
                            {
                                if (unitChecked)
                                {
                                    this.lockUnits = observable(this.lockUnits.filter(u => u !== unit.baseId));
                                } else
                                {
                                    this.lockUnits.push(unit.baseId);
                                }

                            })}>
                            {unit.name}
                        </Checkbox>
                    </div>
                })}

            </div>
        </div>;
    }

    renderTarget()
    {
        return <div>
            <ModWizardTarget
                {...this.props}
                allyCode={this.allyCode}
                trimmedAllyCode={this.getTrimmedAllyCode()}
                allyIsLoaded={this.getAllyIsLoaded()}
                validAllyCode={this.isValidAllyCode()}
                loadedAllies={this.loadedAllies}
                setAllyCode={action((allyCode) => this.allyCode = allyCode)}
                onLoadAlly={action(() => this.onLoadAlly())}
                setGoalAccount={action((target) => this.goalAccount = target)}
                goalAccount={this.goalAccount}
                singleEventRemod={this.singleEventRemod()} />
        </div>;
    }


    createCalcUnitInput(loadoutDefinition: LoadoutDefinition, pps: PlaygroundPlayerSettings): ModAutomationCalculatorUnitInput[]
    {
        let defaultTargets = this.defaultTargets;
        let previousSets: string[][] = [];


        return loadoutDefinition.targets.map(t =>
        {
            let unitData = this.props.gameData.units!.find(u => u.baseId === t.unitBaseId)!;
            return new ModAutomationCalculatorUnitInput(pps, unitData, loadoutDefinition,
                defaultTargets, previousSets);
        });
    }

    generateModAutomationInput(settings: ModAutomationSettings, loadoutDefinition: LoadoutDefinition, pps: PlaygroundPlayerSettings): ModAutomationCalculatorInput
    {
        let source = "WIZARD";
        let units: ModAutomationCalculatorUnitInput[] = this.createCalcUnitInput(loadoutDefinition, pps).filter(mcui =>
            this.lockUnits.includes(mcui.unitBaseId) === false); /// ModAutomationCalculatorUnitInput
        let availableMods = this.getSelectedPlayer()!.mods.filter(mod =>
        {
            const onLockedUnit = mod.equippedCharacter !== null && this.lockUnits.includes(mod.equippedCharacter.baseId);
            return mod.rarity >= 5 && !onLockedUnit;
        });
        let stopIndex = undefined;
        let desiredAsRequiredAboveUnit = undefined;
        let relatedUnits: RelatedUnit[] = []; // RelatedUnit[]

        return new ModAutomationCalculatorInput(source, units, availableMods, stopIndex, desiredAsRequiredAboveUnit, settings, relatedUnits, loadoutDefinition, []);
    }

    renderAutomate()
    {
        let mas = (this.goal === undefined || this.modeSettings.has(this.goal) === undefined) ? this.defaultMas : this.modeSettings.get(this.goal)!.mas;
        let player = this.getSelectedPlayer();
        if (player === undefined)
        {
            return null;
        }
        let loadoutDefinition = this.createLoadoutDefinition();
        let pps: PlaygroundPlayerSettings | undefined = player === undefined ? undefined : new PlaygroundPlayerSettings(player, this.props.gameData, []);

        return <div>
            {player !== undefined &&
                <ModWizardAutomate
                    player={player}
                    includeUnitsNotInBase={this.includeUnitsNotInBase}
                    setIncludeUnitsNotInBase={action((value) => this.includeUnitsNotInBase = value)}
                    pps={pps!}
                    fullRoster={this.singleEventRemod() === false}
                    mci={this.generateModAutomationInput(mas, loadoutDefinition, pps!)}
                    calculatorOutput={this.calculatorOutput}
                    loadoutDefinition={loadoutDefinition}
                    defaultTargets={this.defaultTargets}
                    mas={mas}
                    showStatDetails={this.showStatDetails}
                    tinyScreen={this.tinyScreen}
                    reviewViewModeEnum={this.reviewViewModeEnum}
                    setReviewViewMode={action((mode) => this.reviewViewModeEnum = mode)}
                    setModAutomationSettings={action((mas: ModAutomationSettings) => { this.modeSettings.get(this.goal!)!.mas = mas; this.forceUpdate() })}
                    onCompleteAutomation={(calculatorOutput, mci) => this.handleAutomationOutput(calculatorOutput, mci)}
                    {...this.props} />
            }
        </div>;
    }

    @action
    setBackupName(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.backupLoadoutDefinitionName = e.target.value;
        this.planExecuted = false;
    }
    @action
    setCreateLoadoutDefinitionName(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.createLoadoutDefinitionName = e.target.value;
        this.planExecuted = false;
    }

    backupDefinitionUsed(): boolean
    {
        return this.props.user.currentPlayer!.modLoadouts.find(l => l.name.toUpperCase() === this.backupLoadoutDefinitionName.toUpperCase()) !== undefined;
    }

    newDefinitionUsed(): boolean
    {
        return this.props.user.currentPlayer!.modLoadouts.find(l => l.name.toUpperCase() === this.createLoadoutDefinitionName.toUpperCase()) !== undefined;
    }

    renderUse()
    {

        return <div>
            {this.getGoalAccount() === GoalAccountEnum.Self ?
                <React.Fragment>
                    <div className={styles.paddedrow}>
                        <Checkbox defaultChecked={this.createBackup} checked={this.createBackup}
                            onChange={action((checked) => { this.createBackup = checked.target.checked; this.planExecuted = false; })}>
                            Create Backup Loadout - this is not needed if your current baseline has a loadout you can use to restore.
                        </Checkbox>
                    </div>
                    {
                        this.createBackup &&
                        <React.Fragment>
                            <Input value={this.backupLoadoutDefinitionName} onChange={(e) => this.setBackupName(e)}></Input>

                            {this.backupDefinitionUsed() &&
                                <React.Fragment>
                                    <Alert message="Backout loadout definition in use, will be replaced." type="warning" showIcon />
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    <div className={styles.paddedrow}>
                        <Checkbox defaultChecked={this.generateLoadout} checked={this.generateLoadout}
                            onChange={action((checked) => { this.generateLoadout = checked.target.checked; this.planExecuted = false; })}>
                            Generate Loadout - not needed if you are only pushing straight to game.
                        </Checkbox>
                    </div>
                    {
                        this.generateLoadout &&
                        <React.Fragment>
                            <Input value={this.createLoadoutDefinitionName} onChange={(e) => this.setCreateLoadoutDefinitionName(e)}></Input>
                            {this.newDefinitionUsed() &&
                                <React.Fragment>
                                    <Alert message="New loadout definition in use, will be replaced." type="warning" showIcon />
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    <div className={styles.paddedrow}>
                        <Checkbox defaultChecked={this.pushToGame} checked={this.pushToGame}
                            onChange={action((checked) => { this.pushToGame = checked.target.checked; this.planExecuted = false; })}>
                            Push to Game - attempt to push mod configuration to game.  Will fail if you lack credits or mod inventory space.
                        </Checkbox>
                    </div>

                    <div className={styles.paddedrow}>

                        <h2>CONFIRM: </h2>
                    </div>
                    <div className={styles.paddedrow}>
                        <Checkbox defaultChecked={this.confirmChanges} checked={this.confirmChanges}
                            onChange={action((checked) => { this.confirmChanges = checked.target.checked; this.planExecuted = false; })}>
                            I have reviewed the warnings and confirm that I wish to execute the above changes.
                        </Checkbox>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    Sharable images will be generated for ally accounts.
                </React.Fragment>
            }
            {
                this.planExecuted &&
                <React.Fragment>

                    <h2>Action Complete</h2>

                    <ul>
                        {this.createBackup && this.getGoalAccount() === GoalAccountEnum.Self &&
                            <li>Visit the Loadouts component to view backup and restore after using new loadout.</li>
                        }
                        {this.generateLoadout && this.getGoalAccount() === GoalAccountEnum.Self &&
                            <li>Visit the Loadouts component to view new loadout and push to game.</li>
                        }
                        {
                            this.pushToGame && this.getGoalAccount() === GoalAccountEnum.Self && this.failedPushToGame &&
                            <li>Push to game failed.  Consider creating loadout and pushing that (inventory space, credits, etc can cause failures).</li>
                        }
                        {
                            this.pushToGame && this.getGoalAccount() === GoalAccountEnum.Self && this.failedPushToGame === false &&
                            <li>Mods pushed to game.</li>
                        }
                        {
                            this.imageUrls.length > 0 &&
                            this.imageUrls.map(si => <li key={si.url}><a key={si.url} href={si.url} target="_blank" rel="noopener noreferrer">{si.description}</a> </li>)
                        }
                    </ul>
                </React.Fragment>
            }

        </div>;
    }


    @action
    handleAutomationOutput(calculatorOutput: ModAutomationCalculatorOutput | null, mci: ModAutomationCalculatorInput)
    {
        this.calculatorOutput = null;
        this.planExecuted = false;
        if (calculatorOutput !== null)
        {
            this.calculatorOutput = calculatorOutput;
            this.calculationSummary = getCalculationResultsSummary(mci, calculatorOutput, this.props.gameData.units!);
        }


        console.log("automation complete");
    }

    async setLoadoutDefinitionBase(lds: LoadoutDefintionSummary | undefined)
    {
        if (lds !== undefined)
        {
            await this.fetchLoadoutDefinition(lds);
        }
        runInAction(() =>
        {
            this.loadoutDefinitionSummaryBase = lds;
        });
    }

    async setLdSummary(ldSummary: LoadoutDefintionSummary | undefined, index: number)
    {
        if (ldSummary !== undefined)
        {
            let loadoutDefinition = ldSummary === undefined ? undefined : this.loadoutDefinitions.find(ld =>
                ld.title === ldSummary.title && ld.discordTag === ldSummary.discordTag);

            if (loadoutDefinition === undefined)
                await this.fetchLoadoutDefinition(ldSummary);
        }

        runInAction(() =>
        {
            this.squadSelections[index].loadoutDefinitionSummary = ldSummary;
            this.forceUpdate();
        });

    }

    renderPlan()
    {
        let ldg = this.props.user.currentPlayer!.loadoutDefinitionGroups;

        let ldCategories: string[] = this.goal === undefined || this.modeSettings.has(this.goal) === false ? [] : this.modeSettings.get(this.goal)!.loadoutDefinitionFilters;
        let squadCategories: string[] = this.goal === undefined || this.modeSettings.has(this.goal) === false ? [] : this.modeSettings.get(this.goal)!.squadFilters;
        let maxSize: number = this.goal === undefined || this.modeSettings.has(this.goal) === false ? 5 : this.modeSettings.get(this.goal)!.maxSize;

        return <div>

            <ModWizardSquadManager {...this.props}
                maxSize={maxSize}
                removeAllSquads={action(() => this.squadSelections = [])}
                playerSquadTemplates={this.playerSquadTemplates}
                squadsCategoryFilter={squadCategories}
                squadSelections={this.squadSelections}
                loadoutDefinitionSummaryBase={this.loadoutDefinitionSummaryBase}
                setLoadoutDefinitionBase={(lds) => this.setLoadoutDefinitionBase(lds)}
                fullRoster={this.singleEventRemod() === false}
                existingLoadoutDefinitionGroups={ldg === null ? [] : ldg}
                loadoutDefinitionCategoriesFilter={ldCategories}
                maxSquadSize={MAX_LD_SQUAD_SIZE}
                setLdSummary={(ldSummary, index) => this.setLdSummary(ldSummary, index)}
                onMoveSquadUp={(index) => this.onMoveSquadUp(index)}
                onMoveSquadDown={(index) => this.onMoveSquadDown(index)}

                showSquadSelector={this.showSquadSelector || this.singleEventRemod()}
                setShowSquadSelector={action((value) => this.showSquadSelector = value)}
                unitAvailable={(baseId) => true}
                deleteSquadSelection={(index) => this.deleteSquadSelection(index)}
                onClickUnit={(ss: SquadSelection, unitId: string) => this.onClickUnit(ss, unitId)}
                onAddSquadSelection={(ss) => this.onAddSquadSelection(ss)} />

            {this.missingDefintions() && <React.Fragment>
                <div className={styles.paddedrow}>
                    <h2>Select Definition Base:</h2>
                </div>
                <div className={styles.paddedrow}>
                    <div>
                        <Input className={styles.basedefinition} value={this.loadoutDescription} disabled={true} />

                        <Button onClick={() => this.selectBase()}>Select Base</Button>
                    </div>
                </div>
                <div className={styles.paddedrow}>
                    Some units selected to be modded do not have a specific squad definition.  Select a base defintion for default unit modding.
                </div>
            </React.Fragment>}
        </div>;
    }

    arraymove(fromIndex: number, toIndex: number)
    {
        let newList = this.squadSelections.slice(0);
        var element = this.squadSelections[fromIndex];
        newList.splice(fromIndex, 1);
        newList.splice(toIndex, 0, element);
        return newList;
    }

    @action
    onMoveSquadUp(index: number): void
    {
        this.squadSelections = this.arraymove(index, index - 1);
    }
    @action
    onMoveSquadDown(index: number): void
    {
        this.squadSelections = this.arraymove(index, index + 1);
    }

    @action
    deleteSquadSelection(index: number): void
    {
        this.squadSelections.splice(index, 1);
        this.planExecuted = false;
    }

    @action
    onClickUnit(ss: SquadSelection, unitId: string): any
    {
        if (ss.selectedOptionalUnits.indexOf(unitId) === -1)
        {
            ss.selectedOptionalUnits.push(unitId);
        } else
        {
            ss.selectedOptionalUnits = ss.selectedOptionalUnits.filter(u => u !== unitId);
        }
        this.planExecuted = false;
        this.calculateUnavailableUnits();
        this.forceUpdate();
    }

    renderGoal()
    {

        return <div>

            <div className={styles.paddedrow}>
                Mod Wizard allows you to quickly and easily create loadouts and directly apply mod changes for events.  More complex functionality and manual remodding can be done through Playground.
            </div>

            <div className={styles.paddedrow}>
                <h2>Select Goal:</h2>
            </div>
            <div className={styles.paddedrow}>
                <Radio.Group onChange={(e) => this.setGoal(e)} value={this.goal}>
                    <Space direction="vertical">
                        <Radio value={GoalTypeEnum.FiveVsFive}>Create loadout for entire roster for 5v5 (or TW)</Radio>
                        <Radio value={GoalTypeEnum.ThreeVsThree}>Create loadout for entire roster for 3v3</Radio>
                        <Radio value={GoalTypeEnum.LSTB}>Mod for LSTB (LSTB special missions like KAM included)</Radio>
                        <Radio value={GoalTypeEnum.DSTB}>Mod for DSTB (DSTB special missions like WAT included)</Radio>
                        <Radio value={GoalTypeEnum.ROTE}>Mod for ROTE (ROTE special missions like Reva included)</Radio>
                        <Radio value={GoalTypeEnum.Naboo}>Mod for Naboo</Radio>
                        <Radio value={GoalTypeEnum.CRancor}>Mod for Challenge Rancor</Radio>
                        <Radio value={GoalTypeEnum.HSTR}>Mod for Heroic Sith</Radio>
                        <Radio value={GoalTypeEnum.Journey}>Mod for Journey Guide</Radio>
                        <Radio value={GoalTypeEnum.AssaultBattle}>Mod for Assault Battle</Radio>
                    </Space>
                </Radio.Group>
            </div>



        </div>;
    }

    @action
    nextStep()
    {
        if (this.steps[this.currentStep + 1].skip())
            this.currentStep = this.currentStep + 2;
        else
        {
            this.currentStep = this.currentStep + 1;
        }
    }
    @action
    prevStep()
    {
        if (this.steps[this.currentStep - 1].skip())
            this.currentStep = this.currentStep - 2;
        else
        {
            this.currentStep = this.currentStep - 1;
        }

    }

    currentGoalDescription(): string
    {
        switch (this.goal)
        {
            case GoalTypeEnum.FiveVsFive:
                return "5 v 5"
            case GoalTypeEnum.ThreeVsThree:
                return "3 v 3"
            case GoalTypeEnum.LSTB:
                return "LSTB"
            case GoalTypeEnum.DSTB:
                return "DSTB"
            case GoalTypeEnum.HSTR:
                return "HSTR"
            case GoalTypeEnum.CRancor:
                return "CRancor"
            case GoalTypeEnum.Journey:
                return "Journey"
            case GoalTypeEnum.AssaultBattle:
                return "Assault Battle"
            case GoalTypeEnum.Naboo:
                return "Naboo";
        }

        return "";
    }

    async finish()
    {
        runInAction(() =>
        {
            this.failedPushToGame = false;
            this.imageUrls = [];
        })

        if (this.getGoalAccount() === GoalAccountEnum.Self)
        {
            let units = this.calculatorOutput!.units.filter(u => u.modIds !== null && u.modIds.length > 0).map(u =>
            {
                return {
                    id: u.unitBaseId,
                    modIds: u.modIds!
                }
            });

            let loadoutDefinition = this.createLoadoutDefinition();
            loadoutDefinition.targets.filter(u => this.lockUnits.includes(u.unitBaseId)).forEach(u =>
            {
                units.push({
                    id: u.unitBaseId,
                    modIds: this.getSelectedPlayer()!.mods.filter(mod =>
                    {
                        return mod.equippedCharacter !== null && mod.equippedCharacter.baseId === u.unitBaseId;
                    }).map(mod => mod.id)
                })
            });

            if (this.createBackup)
            {
                await ModEquip.backupCurrentBaseline(this.props.user, this.backupLoadoutDefinitionName, "Baseline", "Baseline backup created from Wizard", true);
            }
            if (this.generateLoadout)
            {
                await ModEquip.createNewLoadout(this.props.user, this.createLoadoutDefinitionName, "Loadout Definition created from wizard", this.currentGoalDescription(),
                    this.createLoadoutDefinition(), units, true);
            }
            if (this.pushToGame)
            {
                let success = await ModEquip.pushModsToGame(this.props.user, this.props.gameData, units);
                runInAction(() =>
                {
                    this.failedPushToGame = success === false;
                })

            } else
            {
                await BaseAPI.fetchFullModSetData(this.props.user, true);
            }
        } else
        {
            let urls: SquadImages[] = [];
            let allyCode = Number(this.getTrimmedAllyCode());

            for (var x = 0; x < this.squadSelections.length; x++)
            {
                let ss = this.squadSelections[x];
                let units = ss.requiredUnits.concat(ss.selectedOptionalUnits);
                let imageOverrides: ImageOverride[] = [];

                units.forEach(unit =>
                {
                    let result = this.calculatorOutput!.units.find(u => u.unitBaseId === unit);
                    if (result !== undefined && result.modIds !== null && result.modIds.length > 0)
                    {
                        result.modIds.forEach(modId =>
                        {
                            imageOverrides.push({

                                allyCode: allyCode,
                                unitBaseId: unit,
                                modId: modId,
                                sliceTo6E: false
                            })
                        });

                    }
                });

                let url = await ModEquip.generateImage(this.props.user, Number(this.getTrimmedAllyCode()), units, imageOverrides);
                if (url !== undefined)  
                {
                    urls.push({
                        description: units.map(u => this.props.gameData.units?.find(gu => gu.baseId === u)!.name).join(","),
                        url: url
                    });
                }
            }
            runInAction(() =>
            {
                this.imageUrls = urls;
            });

        }
        runInAction(() =>
        {
            this.planExecuted = true;
        })
    }

    render()
    {
        if (this.loadoutDefinitionsFetched === false || this.defaultTargetsFetched === false)
        {
            return <BasicLayout {...this.props} requiredApiRights={["MODS_LOADOUTS"]}>
                <React.Fragment>
                    <Spin size="large" />
                </React.Fragment>
            </BasicLayout >;
        }
        return (
            <BasicLayout {...this.props} requiredApiRights={["MODS_LOADOUTS"]} >
                <ModWizardContainer>

                    <Steps current={this.currentStep} direction={this.tinyScreen ? 'vertical' : 'horizontal'} >
                        {this.steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>

                    <div className={styles.stepscontent}>
                        {this.steps[this.currentStep].render()}
                    </div>
                    <div className="steps-action">
                        <div className={styles.navigationbuttons}>
                            {this.currentStep > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={() => this.prevStep()}>
                                    Previous
                                </Button>
                            )}
                            {this.currentStep < this.steps.length - 1 && (
                                <Button type="primary" onClick={() => this.nextStep()} disabled={this.steps[this.currentStep + 1].ready() === false}>
                                    Next
                                </Button>
                            )}
                            {this.currentStep === this.steps.length - 1 && (
                                <Button type="primary" onClick={() => this.finish()} disabled={this.planExecuted === true ||
                                    (this.confirmChanges === false && this.getGoalAccount() === GoalAccountEnum.Self)}>
                                    Generate
                                </Button>
                            )}

                        </div>
                    </div>

                </ModWizardContainer>
            </BasicLayout >
        );
    }

}

export default ModWizard;
