import { observable, action } from "mobx";
import ModData from "./ModData";
import PlayerCharacterData from "./PlayerCharacterData";
import ModLoadoutData from './ModLoadoutData';
import { ModCalculator } from "../utils/mod-calculator";
import PlayerStat from './PlayerStat';
import GrandArena from './GrandArena';
import TerritoryBattleData, { TerritoryBattleHistoryInstance } from './TerritoryBattleData';
import CompareGroup from "./CompareGroup";
import TWTeam from './TWTeam';
import { Template, TemplateGroup } from './Template';
import { IConquestStatus } from "./Conquest";
import { UnitModPreferences, UnitPriorization } from './UnitModPreferences';
import PlayerCharacterSummaryData from "./PlayerCharacterSummaryData";
import { TBHistorySelection } from './TerritoryBattleStatusPreferences';
import { LoadoutDefinition, LoadoutDefintionGroup } from "./LoadoutDefinition";
import { IAllianceShare, IGuildShare } from "./IShare";
import { IDatacron } from "./Datacron";
import { IEpisodeStatus } from "./EpisodeStatus";

class PlayerData
{
    @observable name: string = "";
    @observable allyCode: string = "";
    @observable alt: boolean = false;
    @observable shared: boolean = false;
    @observable hasGoogle: boolean = false;
    @observable hasConnection: boolean = false;
    @observable isSupportLogin: boolean = false;
    @observable supportStaff: boolean = false;
    @observable contentCreator: boolean = false;
    @observable publisher: boolean = false;
    @observable connected: boolean = false;
    @observable readOnly: boolean = false;
    @observable shadow: boolean = false;
    @observable discordTag: string = "";

    @observable userPlayer: boolean = true; // true if this account is "owned" by the player, otherwise (GAC oppenent, guild member, etc)

    @observable title: string = "";
    @observable galacticPower: number = 0;
    @observable galacticPowerCharacter: number = 0;
    @observable galacticPowerShip: number = 0;

    @observable guildName: string = "";
    @observable guildGalacticPower: number = 0;
    @observable guildAdmin: boolean = false;
    @observable guildId: number = 0;
    @observable playerGuildId: string = "";

    @observable credits: number = 0;
    @observable shipCredits: number = 0;
    @observable crystals: number = 0;
    @observable allyPoints: number = 0;
    @observable conquestCredits: number = 0;
    @observable datacronCredits: number = 0;
    @observable microattenuators: number = 0;
    @observable lsTickets: number = 0;
    @observable dsTickets: number = 0;
    @observable mods: ModData[] = [];
    @observable charactersSummary: PlayerCharacterSummaryData | null = null;
    @observable characters: Map<string, PlayerCharacterData> = new Map();
    @observable modLoadouts: ModLoadoutData[] = [];

    @observable baseLineLoadout: ModLoadoutData | undefined;

    @observable loadoutsInitialized: boolean = false;
    @observable archivedLoaded: boolean | undefined = false;
    @observable guildModLoadouts: ModLoadoutData[] | undefined = undefined;
    @observable slicingMats: Map<number, number> = new Map();

    @observable grandArena: GrandArena | null = null;

    @observable territoryBattleData: TerritoryBattleData | null = null; // current tb data
    @observable tbHistorySelections: TBHistorySelection[] = []; // dto with tb data and user settings
    @observable territoryBattleHistory: TerritoryBattleHistoryInstance[] | null = null; // list of available tb data

    @observable shareableAlliances: IAllianceShare[] | null = null;
    @observable shareableGuilds: IGuildShare[] | null = null;

    @observable canSetGlobal: boolean = false;

    @observable stats: Map<string, PlayerStat> = new Map();

    @observable compareGroups: CompareGroup[] = [];

    @observable tWTeams: TWTeam[] | null = null;

    @observable templateGroups: TemplateGroup[] | null = null;

    @observable unitModPreferences: UnitModPreferences[] | null = null;
    @observable unitPriorization: UnitPriorization | null = null;

    @observable conquestStatus: IConquestStatus | null = null;
    @observable notJoinedConquest: boolean = false;

    @observable loadoutDefinitionGroups: LoadoutDefintionGroup[] | null = null;
    @observable loadedDefinitions: LoadoutDefinition[] = [];

    @observable level: number = 0;
    @observable guildRank: number = 0;
    @observable squadRank: number = 0;
    @observable shipRank: number = 0;
    @observable lastActivityUTC: any | number = 0;
    @observable payoutOffset: number = 0;
    @observable nextPayoutUTC: any | number = 0;
    @observable profileAgeMinutes: number = 0;
    @observable gameDataAgeUtc: string | null = "";
    @observable skillRating: number | null = null;
    @observable gacLeague: string | null = null;
    @observable gacDivision: number | null = null;

    @observable gear: Map<string, number> = new Map();
    @observable materials: Map<string, number> = new Map();
    @observable datacrons: IDatacron[] = [];
    @observable episodeStatus: IEpisodeStatus | null = null;

    @observable modsInLoadouts: Map<string, string[]> = new Map();

    @action
    public fromAllJSON(json: any, loadoutsJson: any = null): void
    {
        // this will help speed up base unit calcs
        this.mods = [];
        this.characters = new Map();

        this.charactersSummary = new PlayerCharacterSummaryData(json.data.units.summary);
        this.unitsFromJSON(json.data.units.units);
        this.modsFromJSON(json.data.mods.mods);
        this.summaryFromJSON(json.data.summary);

        if (json.data.grandArena != null)
        {
            this.grandArena = new GrandArena();
            this.grandArena.grandArenaFromJSON(json.data.grandArena);
        }

        this.materialsFromJSON(json.data.material.material);
        this.gearFromJSON(json.data.equipment.equipment);
        if (loadoutsJson != null)
        {
            this.loadoutsFromJSON(loadoutsJson.sets, false);
        } else
        {
            if (this.baseLineLoadout)
            {
                this.baseLineLoadout.testResult = null;
            }
            this.modLoadouts.forEach(ml => ml.testResult = null);
        }
        if (json.data.datacrons)
        {
            this.datacrons = json.data.datacrons;
        }
        else
        {
            this.datacrons = [];
        }
    }

    @action
    public fromDatacronReturnJSON(json: any): void
    {
        this.materialsFromJSON(json.data.material.material);
        if (json.data.summary?.currency)
        {
            this.currencyFromJSON(json.data.summary.currency);
        }
        if (json.data.datacrons)
        {
            this.datacrons = json.data.datacrons;
        }
        else
        {
            this.datacrons = [];
        }
    }

    @action
    public fromConquestStatusJSON(json: any): void
    {
        this.conquestStatus = json.conquest;
        if (json.hasActiveConquest === false)
            this.notJoinedConquest = true;
    }

    @action
    public clearLoadoutTestResults(): void
    {
        if (this.baseLineLoadout)
        {
            this.baseLineLoadout.testResult = null;
        }
        this.modLoadouts.forEach(ml => ml.testResult = null);
    }

    public modInLoadout(mod: ModData): boolean
    {
        return this.modsInLoadouts.has(mod.id);
    }

    @action
    compareGroupsFromJson(json: any): void
    {
        this.compareGroups = [];
        if (json.group)
        {
            json.group.forEach((group: any) => this.compareGroups.push(new CompareGroup(group)));
        }
    }

    @action
    templateGroupsFromJson(json: any): void
    {
        this.templateGroups = [];
        json.group.forEach((g: any) => this.templateGroups!.push(new TemplateGroup(g)));
    }

    findTemplate(id: string): Template | undefined
    {
        let retVal: Template | undefined = undefined;

        if (this.templateGroups)
        {
            this.templateGroups.forEach(tg =>
            {
                tg.template.forEach(t =>
                {
                    if (t.id === id)
                    {
                        retVal = t;
                    }
                })
            })
        }

        return retVal;
    }

    @action
    templateFromJson(json: any): void
    {
        if (this.templateGroups !== null)
        {
            this.templateGroups.forEach(tg =>
            {
                let search = tg.template.find(t => t.id === json.template.id);
                if (search !== undefined)
                {
                    search.fromJson(json.template);
                }
            })
        }
    }

    @action
    public materialsFromJSON(json: any): void
    {
        if (json === null)
            return;

        this.materials.clear();
        this.slicingMats.set(0, 0);
        this.slicingMats.set(1, 0);
        this.slicingMats.set(2, 0);
        this.slicingMats.set(3, 0);
        this.slicingMats.set(4, 0);
        this.slicingMats.set(5, 0);
        this.slicingMats.set(6, 0);
        this.slicingMats.set(7, 0);
        this.slicingMats.set(8, 0);
        this.slicingMats.set(9, 0);
        this.slicingMats.set(10, 0);

        for (let m of json)
        {
            if (m.id === "MOD_SLICING_SALVAGE_TIER05_01")
                this.slicingMats.set(0, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER05_02")
                this.slicingMats.set(1, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER05_03")
                this.slicingMats.set(2, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER05_04")
                this.slicingMats.set(3, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER05_05")
                this.slicingMats.set(4, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER05_06")
                this.slicingMats.set(5, m.quantity);
            else if (m.id === "MOD_SLICING_PROMOTION_MATERIAL_T5_TO_T6")
                this.slicingMats.set(6, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER06_01")
                this.slicingMats.set(7, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER06_02")
                this.slicingMats.set(8, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER06_03")
                this.slicingMats.set(9, m.quantity);
            else if (m.id === "MOD_SLICING_SALVAGE_TIER06_04")
                this.slicingMats.set(10, m.quantity);
            this.materials.set(m.id, m.quantity + m.bonusQuantity);
        }
    }

    @action
    public gearFromJSON(json: any): void
    {
        if (json === null)
            return;

        for (let g of json)
        {
            this.gear.set(g.id, g.quantity + g.bonusQuantity);
        }
    }

    @action
    public fromAllyJSON(json: any, userPlayer: boolean): void
    {
        this.accountDataFromJson(json.summary);
        // this will help speed up base unit calcs
        this.mods = [];
        this.characters = new Map();

        this.charactersSummary = new PlayerCharacterSummaryData(json.units.summary);
        this.unitsFromJSON(json.units.units);
        this.modsFromJSON(json.mods.mods);
        this.summaryFromJSON(json.summary);

        this.userPlayer = userPlayer;
    }

    @action
    public loadoutDefintionGroupsFromJson(json: any): void
    {
        this.loadoutDefinitionGroups = json.groupings.filter((g: any) => g.groupName !== undefined).map((g: any) => new LoadoutDefintionGroup(g));
        if (json.canSetGlobal) this.canSetGlobal = json.canSetGlobal;
    }

    @action
    public tbFromJSON(json: any): void
    {
        this.territoryBattleData = TerritoryBattleData.fromJSON(json);
    }

    @action
    public tbHistoryFromJSON(json: any): void
    {
        if (json.instance)
        {
            this.territoryBattleHistory = json.instance.map((instance: any) => new TerritoryBattleHistoryInstance(instance));
        }
    }

    @action
    public twTeamsFromJson(json: any): void
    {
        if (json.team)
        {
            this.tWTeams = [];
            json.team.forEach((t: any) => this.tWTeams!.push(new TWTeam(t)));
        }
    }

    @action
    public modsFromJSON(json: any): void
    {
        if (json === null)
            return;

        this.mods = json.map((m: any) => new ModData(m, this.characters)).sort((a: ModData, b: ModData) =>
        {
            if (a.id < b.id)
                return -1;
            if (a.id > b.id)
                return 1;
            return 0;
        });
        this.calculateCharacterBaseStats();
    }

    @action
    public unitsFromJSON(json: any): void
    {
        if (json != null)
        {
            for (let u of json)
            {
                let c = this.characters.get(u.baseId);
                if (!c)
                {
                    c = new PlayerCharacterData(u);
                    this.characters.set(u.baseId, c);
                }
            }
        }
        this.calculateCharacterBaseStats();
    }

    private calculateCharacterBaseStats()
    {
        try
        {
            this.characters.forEach((pcd: PlayerCharacterData, key: string) =>
            {
                pcd.mods = [];
            });

            this.mods.forEach(mod =>
            {
                if (mod.equippedCharacter !== null && mod.equippedCharacter !== undefined)
                {
                    mod.equippedCharacter.mods.push(mod);
                }
            });

            this.characters.forEach((pcd: PlayerCharacterData, key: string) =>
            {
                let characterMods = pcd.mods;
                pcd.baseStats = ModCalculator.deriveBaseStats(pcd, characterMods);
            });
        } catch (err)
        {
            console.log("error calculating character base stats");
            throw err;
        }
    }


    @action
    public statsFromJSON(json: any): void
    {
        if (json == null)
            return;

        for (let s of json)
        {
            let c = this.stats.get(s.key);
            if (!c)
            {
                c = new PlayerStat(s.key, s.name, s.value, s.valueStr);
                this.stats.set(s.key, c);
            }
        }
    }

    @action
    public summaryFromJSON(json: any): void
    {
        this.title = json.title;
        this.galacticPower = json.galacticPower;
        this.galacticPowerCharacter = json.galacticPowerCharacter;
        this.galacticPowerShip = json.galacticPowerShip;
        this.guildName = json.guildName;
        this.guildId = json.guildId;
        this.playerGuildId = json.playerGuildId;
        this.guildGalacticPower = json.guildGalacticPower;
        this.guildAdmin = json.guildAdmin;
        this.discordTag = json.discordTag;
        this.level = json.level;
        this.guildRank = json.guildRank;
        this.shipRank = json.shipRank;
        this.squadRank = json.squadRank;
        this.lastActivityUTC = json.lastActivityUTC;
        this.payoutOffset = json.payoutOffset;
        this.nextPayoutUTC = json.nextPayoutUTC;
        this.profileAgeMinutes = json.profileAgeMinutes;
        this.hasConnection = json.hasGameConnection;
        this.readOnly = json.readOnly;
        this.shadow = json.shadow;
        this.gameDataAgeUtc = json.gameDataAgeUtc;
        this.skillRating = json.skillRating;
        this.isSupportLogin = json.isSupportLogin;
        this.supportStaff = json.supportStaff;
        this.contentCreator = json.contentCreator;
        this.publisher = json.publisher === true;
        this.connected = json.connected;
        this.gacLeague = json.leagueId;
        this.gacDivision = json.divisionId;

        if (json.allyCode)
        {
            this.allyCode = json.allyCode.toString();
        }

        if (json.currency)
        {
            this.currencyFromJSON(json.currency);
        }
        if (json.alliances)
            this.shareableAlliances = json.alliances;
        if (json.guilds)
            this.shareableGuilds = json.guilds;
        if (json.stats)
            this.statsFromJSON(json.stats.stats);
        if (json.episodeStatus)
            this.episodeStatus = json.episodeStatus;
    }

    @action
    public currencyFromJSON(json: any): void
    {
        if (json == null)
            return;
        for (let c of json)
        {
            switch (c.currency)
            {
                case 1:
                    this.credits = c.quantity;
                    break;
                case 2:
                    this.crystals = c.quantity;
                    break;
                case 4:
                    this.allyPoints = c.quantity;
                    break;
                case 19:
                    this.shipCredits = c.quantity;
                    break;
                case 36:
                    this.lsTickets = c.quantity;
                    break;
                case 37:
                    this.dsTickets = c.quantity;
                    break;
                case 39:
                    this.conquestCredits = c.quantity;
                    break;
                case 40:
                    this.datacronCredits = c.quantity;
                    break;
                case 41:
                    this.microattenuators = c.quantity;
                    break;
            }
        }
    }

    @action
    public loadoutsFromJSON(json: any, archived: boolean | undefined): void
    {
        this.loadoutsInitialized = true;
        this.archivedLoaded = archived;
        this.modLoadouts = json.filter((m: any) => m.baseLine !== true).map((m: any) => new ModLoadoutData(m, this.characters, this));
        const baselineLoadout = json.find((m: any) => m.baseLine === true);
        this.baseLineLoadout = undefined;
        if (baselineLoadout)
        {
            this.baseLineLoadout = new ModLoadoutData(baselineLoadout, this.characters, this)
        }
        this.modsInLoadouts = new Map();
        json.forEach((loadoutData: any) =>
        {
            loadoutData.units?.forEach((unit: any) =>
            {
                unit.modIds?.forEach((modId: string) =>
                {
                    if (this.modsInLoadouts.has(modId) === false)
                    {
                        this.modsInLoadouts.set(modId, []);
                    }
                    this.modsInLoadouts.get(modId)?.push(loadoutData.name)
                });
            });
        });

    }


    @action
    public loadoutFromJSON(json: any): void
    {
        const newLoadout = new ModLoadoutData(json, this.characters, this);
        this.modLoadouts = this.modLoadouts.filter(l => l.name !== newLoadout.name);
        this.modLoadouts.push(newLoadout);
    }

    @action
    public guildLoadoutsFromJSON(json: any): void
    {
        this.guildModLoadouts = json.map((m: any) => new ModLoadoutData(m, this.characters, this));
    }

    public fromCraftingResponse(json: any): void
    {
        this.currencyFromJSON(json.currency);
        this.gearFromJSON(json.equipment);
        this.materialsFromJSON(json.material);
    }

    public get creditsDisplayValue(): string
    {
        if (this.credits >= 1000000000)
        {
            return (this.credits / 1000000000).toFixed(1) + "B";
        }
        else if (this.credits >= 1000000)
        {
            return (this.credits / 1000000).toFixed(1) + "M";
        }
        else
        {
            return (this.credits / 1000).toFixed(0) + "K";
        }
    }

    private accountDataFromJson(json: any)
    {
        this.name = json.name;
        this.allyCode = json.allyCode === undefined ? this.allyCode : json.allyCode.toString();
        this.alt = json.alt;
        this.shared = json.shared;
        this.hasGoogle = json.hasGoogle;
        this.hasConnection = json.hasConnection;
        this.readOnly = json.readOnly;
    }

    public constructor(json?: any)
    {
        this.slicingMats.set(0, 0);
        this.slicingMats.set(1, 0);
        this.slicingMats.set(2, 0);
        this.slicingMats.set(3, 0);
        this.slicingMats.set(4, 0);
        this.slicingMats.set(5, 0);
        this.slicingMats.set(6, 0);
        this.slicingMats.set(7, 0);
        this.slicingMats.set(8, 0);
        this.slicingMats.set(9, 0);
        this.slicingMats.set(10, 0);
        if (json)
        {
            this.accountDataFromJson(json);
        }
    }
}

export default PlayerData;
