import styles from "../styles/PlanetSelection.module.scss";
import React from "react";
import IPageProps from "../../../IPageProps";
import {extraPlanets, IROTEZoneType, ITBRotePlanetsData} from "../../../../model/ROTETBData";
import {Select} from "antd";
import {observer} from "mobx-react";
import {
	TBRoteLocationZoneStatus
} from "../../../../model/TerritoryBattleData";
import {toJS} from "mobx";
import {compactMap} from "./utils";

interface ICCPlanetSelectionProps extends IPageProps {
	data: ITBRotePlanetsData[];
	templateZoneLocation?: TBRoteLocationZoneStatus;
	phase: number;
	onChangePlanet: (location: IROTEZoneType, value: ITBRotePlanetsData | undefined) => void;
	onChangeBonus: (location: IROTEZoneType, value: ITBRotePlanetsData[]) => void;
}

@observer
export default class CCPlanetSelection extends React.PureComponent<ICCPlanetSelectionProps> {

	render() {
		const handleChange = (zone: IROTEZoneType, value: string) => {
			if (!value) {
				this.props.onChangePlanet(zone, undefined);
				return;
			}
			const planetData = this.props.data.find((x) => x.planetName === value);

			this.props.onChangePlanet(zone, toJS(planetData));
		};

		const handleChangeBonus = (zone: IROTEZoneType, value: string[]) => {
			let planetsData: ITBRotePlanetsData[] = [];

			for (let i = 0; i < value.length; i++) {
				const planetData = this.props.data.find((x) => x.planetName === value[i]);

				if (planetData) {
					planetsData.push(planetData);
				}
			}
			this.props.onChangeBonus(zone, planetsData);
		};

		// Check if bonusPlanets is an array and default to an empty array if not
		const bonusPlanets = Array.isArray(this.props.templateZoneLocation?.bonus) ? this.props.templateZoneLocation?.bonus : [this.props.templateZoneLocation?.bonus];

		const bonusPlanetsName = compactMap(bonusPlanets ?? [], (x) => x?.planetName);

		return (
			<div className={`${styles.container} ${styles.space80}`}>
				<SelectionPlanet
					data={this.props.data}
					title={'Dark Side'}
					forceAlignment={3}
					handleChange={(value) => handleChange('top', value)}
					planetName={this.props.templateZoneLocation?.top?.planetName}
				/>
				<SelectionPlanet
					data={this.props.data}
					title={'Neutral Side'}
					forceAlignment={1}
					handleChange={(value) => handleChange('middle', value)}
					planetName={this.props.templateZoneLocation?.middle?.planetName}
				/>
				<SelectionPlanet
					data={this.props.data}
					title={'Light Side'}
					forceAlignment={2}
					handleChange={(value) => handleChange('bottom', value)}
					planetName={this.props.templateZoneLocation?.bottom?.planetName}
				/>
				<BonusSelectionPlanets
					data={this.props.data}
					handleChange={(value) => handleChangeBonus('bonus', value)}
					planetNames={bonusPlanetsName}
				/>
			</div>
		);
	}
}

interface ISelectionPlanetProps {
	data: ITBRotePlanetsData[];
	title: string;
	forceAlignment: number;
	handleChange: (value: string) => void;
	planetName?: string;
}

function SelectionPlanet(props: ISelectionPlanetProps) {
	const optionsSelection = () => {
		const filteredItems = props.data.filter((x) => x.forceAlignment === props.forceAlignment && !extraPlanets.includes(x.planetName.toLowerCase()));

		return filteredItems.map((p, idx) => ({
			key: p.zoneId,
			value: p.planetName,
			label: `${p.planetName} - (Phase ${idx + 1})`
		}));

	};

	return (
		<div className={styles.cell}>
			<div className={styles.label}>{props.title}</div>
			<Select
				placeholder={`Select a ${props.title} planet`}
				className={styles.select}
				onChange={props.handleChange}
				allowClear={true}
				value={props.planetName}
				options={optionsSelection()}
			/>
		</div>
	);
}

interface IBonusSelectionPlanetsProps {
	data: ITBRotePlanetsData[];
	handleChange: (value: string[]) => void;
	planetNames?: string[];
}

function BonusSelectionPlanets(props: IBonusSelectionPlanetsProps) {
	const optionsSelection = () => {
		const filteredItems = props.data.filter((item) => extraPlanets.includes(item.planetName.toLowerCase()));

		return filteredItems.map((p, idx) => ({
			key: p.zoneId,
			value: p.planetName,
			label: p.planetName
		}));
	};

	return (
		<div className={styles.cell}>
			<div className={styles.label}>Bonus</div>
			<Select
				placeholder={`Select at least one planet`}
				className={styles.select}
				onChange={props.handleChange}
				allowClear={true}
				defaultValue={props.planetNames}
				options={optionsSelection()}
				mode={'multiple'}
			/>
		</div>
	);
}