import UserData from './../../model/UserData';
import TerritoryBattleStatusPreferences, {TBHistorySelection} from './../../model/TerritoryBattleStatusPreferences';
import TerritoryBattleData, {
	TBMissionTracker,
	TerritoryBattlePlatoonStatus,
	TBPlatoonZoneStats,
	TBPlatoonUnitStatus,
	TBReconZoneStatus,
	TBAllZones,
	TBRoteZoneData, TBRoteAllZones, TZoneType, TBRoteLocationEnum,
} from './../../model/TerritoryBattleData';
import BaseAPI from "../../service/BaseAPI";
import TbStatusCalcs, {CombatMissionReward} from './tb-status-calcs';
import TerritoryBattleGameData from './../../model/TerritoryBattleGameData';
import {runInAction} from 'mobx';
import {IROTEZoneType, TBRoteLocationZoneList} from "../../model/ROTETBData";

export interface WaveCombinationSelection {
	allyCode: number;
	phase: number;
	rewards: CombatMissionReward[];
}

export class TBController {
	static PERMISSION_KEY = "TERRITORY_BATTLES";
	public static TB_MISSION_WAVES_KEY = "tb_mission_waves_";

	public static hasTBRights(user: UserData) {
		if (!user.apiRights)
			return false;

		for (let r of [TBController.PERMISSION_KEY]) {
			if (!user.apiRights.includes(r))
				return false;
		}
		return true;
	}

	static async getCurrentPlatoonStatus(user: UserData, refresh: boolean): Promise<TerritoryBattlePlatoonStatus[]> {
		let retVal: TerritoryBattlePlatoonStatus[] = [];
		user.currentPlayer!.territoryBattleData = refresh ? null : user.currentPlayer!.territoryBattleData;
		await BaseAPI.fetchTbData(user, refresh);
		if (user.currentPlayer!.territoryBattleData !== null) {
			user.currentPlayer!.territoryBattleData.reconZoneStatus.filter(rzs => rzs.status.zoneState === TerritoryBattleData.ZONE_OPEN).forEach(rzs => {
				let phase = rzs.getPhase();
				let location = rzs.getLocation(user.currentPlayer!.territoryBattleData!) as TBRoteLocationEnum;
				let newTbps = new TerritoryBattlePlatoonStatus(phase, location, rzs.getZoneId());
				retVal.push(newTbps);
				rzs.platoon.forEach(p => {
					let index = p.getPlatoonIndex();
					// fucking CG!!!!
					if (user.currentPlayer!.territoryBattleData!.definitionId === "t05D")
						index = 7 - index;
					let unitStatus = new TBPlatoonZoneStats();
					newTbps.zoneStatus.set(index, unitStatus);

					p.squad.forEach(s => {
						s.unit.forEach(u => {
							let ups = new TBPlatoonUnitStatus(u.getUnitBaseId(), u.playerName);
							unitStatus.platoonUnitStatus.push(ups)
						});
					})
				});
			});
		}
		return retVal;
	}

	static async getZonesStatus(user: UserData, refresh: boolean): Promise<TBAllZones[]> {
		let retVal: TBAllZones[] = [];
		let retZoneVal: TBReconZoneStatus[] = [];
		let retPlatoonVal: TBReconZoneStatus[] = [];
		let retCMVal: TBReconZoneStatus[] = [];
		let retSMVal: TBReconZoneStatus[] = [];

		runInAction(() => user.currentPlayer!.territoryBattleData = refresh ? null : user.currentPlayer!.territoryBattleData);
		await BaseAPI.fetchTbData(user, refresh);
		if (user.currentPlayer!.territoryBattleData !== null) {
			user.currentPlayer!.territoryBattleData.conflictZoneStatus.forEach(rzs => {
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhase();
				const location = rzs.getLocation();
				const specialText = rzs.getSpecialText();
				const commandState = rzs.getCommandState();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retZoneVal.push(newTbps);
			});
			user.currentPlayer!.territoryBattleData.reconZoneStatus.forEach(rzs => {
				// console.log('reconZoneStatus: ', rzs.getSpecialText());
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhaseNo();
				const location = rzs.getLocationNo();
				const specialText = rzs.getSpecialText();
				const commandState = rzs.getCommandState();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retPlatoonVal.push(newTbps);
			});
			user.currentPlayer!.territoryBattleData.convertZoneStatus.forEach(rzs => {
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhase();
				const location = rzs.getLocation();
				const commandState = rzs.getCommandState();
				const specialText = rzs.getSpecialText();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retSMVal.push(newTbps);
			});
			user.currentPlayer!.territoryBattleData.strikeZoneStatus.forEach(rzs => {
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhase();
				const location = rzs.getLocation();
				const specialText = rzs.getSpecialText();
				const commandState = rzs.getCommandState();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retCMVal.push(newTbps);
			});
			retVal.push({
				zoneType: "zone",
				data: retZoneVal
			}, {
				zoneType: "combat_mission",
				data: retCMVal
			}, {
				zoneType: "platoon",
				data: retPlatoonVal
			}, {
				zoneType: "special_mission",
				data: retSMVal
			})
		}
		return retVal;
	}

	static async getRoteZonesStatus(user: UserData, refresh: boolean): Promise<TBRoteLocationZoneList> {
		let retVal: TBRoteLocationZoneList = {} as TBRoteLocationZoneList;
		let retZoneVal: TBReconZoneStatus[] = [];
		let retPlatoonVal: TBReconZoneStatus[] = [];
		let retCMVal: TBReconZoneStatus[] = [];
		let retSMVal: TBReconZoneStatus[] = [];

		runInAction(() => user.currentPlayer!.territoryBattleData = refresh ? null : user.currentPlayer!.territoryBattleData);
		await BaseAPI.fetchTbData(user, refresh);
		if (user.currentPlayer!.territoryBattleData !== null) {
			user.currentPlayer!.territoryBattleData.conflictZoneStatus.forEach(rzs => {
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhase();
				const location = rzs.getLocation();
				const specialText = rzs.getSpecialText();
				const commandState = rzs.getCommandState();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retZoneVal.push(newTbps);
			});
			user.currentPlayer!.territoryBattleData.reconZoneStatus.forEach(rzs => {
				// console.log('reconZoneStatus: ', rzs.getSpecialText());
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhaseNo();
				const location = rzs.getLocationNo();
				const specialText = rzs.getSpecialText();
				const commandState = rzs.getCommandState();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retPlatoonVal.push(newTbps);
			});
			user.currentPlayer!.territoryBattleData.convertZoneStatus.forEach(rzs => {
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhase();
				const location = rzs.getLocation();
				const commandState = rzs.getCommandState();
				const specialText = rzs.getSpecialText();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retSMVal.push(newTbps);
			});
			user.currentPlayer!.territoryBattleData.strikeZoneStatus.forEach(rzs => {
				const zoneId = rzs.getZoneId();
				const phase = rzs.getPhase();
				const location = rzs.getLocation();
				const specialText = rzs.getSpecialText();
				const commandState = rzs.getCommandState();
				const commandMessage = rzs.getCommandMessage();
				const newTbps = new TBReconZoneStatus(zoneId, phase, location, specialText, commandMessage, commandState);
				retCMVal.push(newTbps);
			});

			const getDataZone = (location: number, type: string, bonus: boolean = false, zoneId?: string) => {

				if (type === "zone") {
					return retZoneVal.filter(rzs => rzs.location === location && rzs.zoneId.includes("bonus") === bonus && (zoneId === undefined || rzs.zoneId.includes(zoneId)));
				}
				if (type === "special_mission") {
					return retSMVal.filter(rzs => rzs.location === location && rzs.zoneId.includes("bonus") === bonus && (zoneId === undefined || rzs.zoneId.includes(zoneId)));
				}
				if (type === "combat_mission") {
					return retCMVal.filter(rzs => rzs.location === location && rzs.zoneId.includes("bonus") === bonus && (zoneId === undefined || rzs.zoneId.includes(zoneId)) && rzs.zoneId.includes("_strike"));
				}
				if (type === "platoon") {
					return retPlatoonVal.filter(rzs => rzs.location === location && rzs.zoneId.includes("bonus") === bonus && (zoneId === undefined || rzs.zoneId.includes(zoneId)));
				}

				return [];
			}

			const getDataLocation = (location: number, bonus: boolean): TBRoteZoneData[] => {
				const result: TBRoteZoneData[] = [];
				const zoneTypes: TZoneType[] = ["zone", "combat_mission", "platoon", "special_mission"];
				const zoneDataId = getDataZone(location, "zone", bonus).map(rzs => rzs.zoneId);

				const locations = [
					{name: 'top', number: 2, bonus: false},
					{name: 'middle', number: 3, bonus: false},
					{name: 'bottom', number: 1, bonus: false},
					{name: 'bonus', number: 4, bonus: true}
				];


				if (zoneDataId.length > 0) {
					const foundLocation = locations.find(l => l.number === location)!.name as IROTEZoneType;

					zoneDataId.forEach(zoneId => {
						const zoneData: TBRoteAllZones[] = zoneTypes.map((zoneType) => {
							return {
								zoneType: zoneType,
								data: getDataZone(location, zoneType, bonus, zoneId)
							}
						});
						result.push({
							location: foundLocation,
							zoneId: zoneId,
							data: zoneData
						});
					});
				}

				return result;
			};

			const obj = {
				top: [] as TBRoteZoneData[],
				middle: [] as TBRoteZoneData[],
				bottom: [] as TBRoteZoneData[],
				bonus: [] as TBRoteZoneData[]
			};

			obj.top = getDataLocation(2, false);
			obj.middle = getDataLocation(3, false);
			obj.bottom = getDataLocation(1, false);
			obj.bonus = getDataLocation(4, true);

			retVal = obj
		}
		return retVal;
	}

	static async generateCurrentTbHistorySelection(user: UserData, tbGameData: TerritoryBattleGameData, refresh: boolean) {
		try {
			await BaseAPI.fetchTbData(user, refresh);
			let tbData = user.currentPlayer!.territoryBattleData;
			if (tbData !== null && tbData.currentRound !== null) {
				if (tbData !== undefined) {
					let tbsp = await TerritoryBattleStatusPreferences.fetchStatusPreferences(user, tbData.instanceId, tbData.currentRound!);
					let unitMissionTrackingData = await TBController.getUnitMissionTrackingData(user, tbData, tbGameData);
					let fleetMissionTrackingData = await TBController.getFleetMissionTrackingData(user, tbData, tbGameData);
					let tbHistorySelection = new TBHistorySelection(tbData, tbsp, unitMissionTrackingData, fleetMissionTrackingData);

					runInAction(() => {
						user.currentPlayer!.tbHistorySelections.push(tbHistorySelection!);
					});
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	static async getTbHistorySelection(user: UserData, tbGameData: TerritoryBattleGameData, instanceId: string, phase: number, forceUpdate: boolean = false): Promise<TBHistorySelection> {
		let retVal = user.currentPlayer!.tbHistorySelections.find(tbhs => tbhs.tbData.instanceId === instanceId && tbhs.tbData.currentRound === phase);

		if (retVal === undefined || forceUpdate) {
			let tbData = await BaseAPI.fetchHistoricalTbData(user, instanceId, phase);
			if (tbData !== undefined) {
				let tbsp = await TerritoryBattleStatusPreferences.fetchStatusPreferences(user, tbData.instanceId, tbData.currentRound!);
				let unitMissionTrackingData = await TBController.getUnitMissionTrackingData(user, tbData, tbGameData);
				let fleetMissionTrackingData = await TBController.getFleetMissionTrackingData(user, tbData, tbGameData);
				retVal = new TBHistorySelection(tbData, tbsp, unitMissionTrackingData, fleetMissionTrackingData);

				runInAction(() => {
					user.currentPlayer!.tbHistorySelections = user.currentPlayer!.tbHistorySelections.filter(tbhs => tbhs.tbData.instanceId !== instanceId || tbhs.tbData.currentRound !== phase);
					user.currentPlayer!.tbHistorySelections.push(retVal!);
				});
			}
		}
		return retVal!;
	}

	static async getUnitMissionTrackingData(user: UserData, tbData: TerritoryBattleData, tbGameData: TerritoryBattleGameData): Promise<TBMissionTracker[]> {
		let retVal: TBMissionTracker[] = [];

		let unitMissionTrackerKey = TBMissionTracker.SETTING_KEY + "_unit_" + tbData.instanceId + "_" + tbData.currentRound;
		let unitMissionTrackingDataString = await BaseAPI.getGuildSetting(user, unitMissionTrackerKey);

		if (unitMissionTrackingDataString.length > 0) {
			retVal = JSON.parse(unitMissionTrackingDataString[0].value);
		}
		// update the "progress points, if applicable"
		let unitMaxPointsRemaining = TbStatusCalcs.getMissionPointsRemaining(tbData, tbGameData, false);
		let unitCurrent = TbStatusCalcs.getMissionCurrentPointValue(tbData, tbGameData, false);
		if (retVal.find(t => t.maxPointsRemaining === unitMaxPointsRemaining) === undefined) {
			retVal.push({
				maxPointsRemaining: unitMaxPointsRemaining,
				currentMissionPointValue: unitCurrent
			});
			await BaseAPI.setGuildSetting(user, unitMissionTrackerKey, JSON.stringify(retVal), true);
		}
		return retVal;
	}

	static async getFleetMissionTrackingData(user: UserData, tbData: TerritoryBattleData, tbGameData: TerritoryBattleGameData): Promise<TBMissionTracker[]> {
		let retVal: TBMissionTracker[] = [];

		let fleetMissionTrackerKey = TBMissionTracker.SETTING_KEY + "_fleet_" + tbData.instanceId + "_" + tbData.currentRound;

		let fleetMissionTrackingDataString = await BaseAPI.getGuildSetting(user, fleetMissionTrackerKey);

		if (fleetMissionTrackingDataString.length > 0) {
			retVal = JSON.parse(fleetMissionTrackingDataString[0].value);
		}

		let fleetMaxPointsRemaining = TbStatusCalcs.getMissionPointsRemaining(tbData, tbGameData, true);
		let fleetCurrent = TbStatusCalcs.getMissionCurrentPointValue(tbData, tbGameData, true);
		if (retVal.find(t => t.maxPointsRemaining === fleetMaxPointsRemaining) === undefined) {
			retVal.push({
				maxPointsRemaining: fleetMaxPointsRemaining,
				currentMissionPointValue: fleetCurrent
			});
			await BaseAPI.setGuildSetting(user, fleetMissionTrackerKey, JSON.stringify(retVal), true);
		}
		return retVal;
	}
}