import { computed, observable } from "mobx";
import ModData from './ModData';
import PlayerCharacterData from './PlayerCharacterData';
import PlayerData from "./PlayerData";
import ConfigurableSetting from './ConfigurableSetting';
import BaseAPI from './../service/BaseAPI';
import UserData from './UserData';
import { LoadoutDefinition } from "./LoadoutDefinition";


export class APILoadoutUnit
{
    id: string;
    modIds: string[] = [];

    constructor(json: any)
    {
        this.id = json.id;
        if (json.modIds)
        {
            this.modIds = json.modIds;
        }
    }
}

export class APILoadout
{
    public static GUILD_SOURCE = "guild";
    public static PLAYER_SOURCE = "player";

    category: string;
    name: string;
    description: string | null;
    units: APILoadoutUnit[] = [];
    author: string | undefined;

    source: string = APILoadout.PLAYER_SOURCE;

    public static ALLY_KEY = "mod_loadouts_";

    constructor(json: any)
    {
        this.category = json.category;
        this.name = json.name;
        this.description = json.description;
        this.author = json.author;
        if (json.units)
        {
            this.units = json.units.map((u: any) => new APILoadoutUnit(u));
        }
    }


    public static async allyLoadouts(user: UserData, allyCode: string, sourceType: string | undefined = undefined): Promise<APILoadout[]>
    {
        let retVal: APILoadout[] = [];

        if (sourceType === undefined || sourceType === APILoadout.PLAYER_SOURCE)
        {
            let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user,
                APILoadout.ALLY_KEY + allyCode);


            if (settings.length > 0)
            {
                retVal = JSON.parse(settings[0].value);
            }
        }

        if (sourceType === undefined || sourceType === APILoadout.GUILD_SOURCE)
        {
            let guildSettings: ConfigurableSetting[] = await BaseAPI.getGuildSetting(user,
                APILoadout.ALLY_KEY + allyCode);
            if (guildSettings.length > 0)
            {
                let guildLoadouts: APILoadout[] = JSON.parse(guildSettings[0].value);
                guildLoadouts.forEach(al => al.source = APILoadout.GUILD_SOURCE);
                // guild loadouts take precedence
                retVal = retVal.filter(pl => guildLoadouts.find(gl => gl.name === pl.name) === undefined).concat(guildLoadouts);
            }
        }
        return retVal;
    }
}

export class ModLoadoutUnit
{
    private player: PlayerData;
    @observable modIds: string[] = [];

    @observable unit: PlayerCharacterData | null = null;
    @observable missingMod: boolean = false;

    @computed get mods(): ModData[]
    {
        return this.modIds.reduce((result: ModData[], modId: string) =>
        {
            let mod: ModData = this.player.mods.find(m => m.id === modId)!;
            if (mod != null)
            {
                result.push(mod);
            }
            return result;
        }, []);
    }

    constructor(json: any, characterMap: Map<string, PlayerCharacterData>, player: PlayerData)
    {
        this.player = player;
        this.unit = Array.from(characterMap.values()).find((unit: PlayerCharacterData) => unit.id === json.id)!;
        if (this.unit == null)
        {
            console.error("Unable to find unit in loadout: " + json.id);
        }
        if (json.modIds != null)
        {
            this.modIds = json.modIds;
            this.missingMod = this.mods.length !== json.modIds.length;
        }
    }
}

export class ModLoadoutTest
{
    cost: number;
    isCurrent: boolean;
    canEquip: boolean;
    error: null | string;
    missingModIdList: string[];
    illegalModIdList: string[];

    constructor(json: any)
    {
        this.cost = json.cost;
        this.isCurrent = json.isCurrent;
        this.canEquip = json.canEquip;
        this.error = json.error;
        this.missingModIdList = json.missingModIdList;
        this.illegalModIdList = json.illegalModIdList;
    }
}

export class ModLoadoutData
{
    @observable name: string = "";
    @observable archived: boolean = false;

    characterMap: Map<string, PlayerCharacterData>;
    player: PlayerData;
    _modLoadoutUnitJson: any;
    _modLoadoutUnitData: ModLoadoutUnit[] | null = null;
    public get modLoadoutUnits(): ModLoadoutUnit[]
    {
        console.log('loading mod unit data');
        if (this._modLoadoutUnitData === null)
        {
            this._modLoadoutUnitData = this._modLoadoutUnitJson.map((unit: any) => new ModLoadoutUnit(unit, this.characterMap, this.player));
        }
        return this._modLoadoutUnitData!;
    }

    @observable unitCount: number = 0;

    //@observable modLoadoutUnits: ModLoadoutUnit[] = [];

    //  @observable missingMod: boolean = false;
    @observable category: string | null = null;
    @observable testResult: ModLoadoutTest | null = null;
    @observable createdStamp: Date | null = null;
    @observable updatedStamp: Date | null = null;
    @observable description: string | null = null;
    @observable author: string | undefined;
    @observable statDefinition: LoadoutDefinition | undefined;
    @observable lockedUnits: string[];
    @observable baseLine: boolean = false;
    @observable revisions: ModLoadoutData[] = [];
    @observable baseLineIndex: number | undefined;


    constructor(json: any, characterMap: Map<string, PlayerCharacterData>, player: PlayerData)
    {
        this.name = json.name;
        this.category = json.category;
        this.author = json.author;
        this.archived = json.archived;
        this.baseLine = json.baseLine;
        this.baseLineIndex = json.baseLineIndex;

        this.createdStamp = json.createdStamp === undefined ? null : json.createdStamp;
        this.updatedStamp = json.updatedStamp === undefined ? null : json.updatedStamp;
        this.description = json.description === undefined ? null : json.description;
        this.lockedUnits = json.lockedUnits === undefined || json.lockedUnits === null || json.lockedUnits.trim() === '' ? [] : json.lockedUnits.split(",");

        this.characterMap = characterMap;
        this.player = player;

        if (json.units != null)  
        {
            this._modLoadoutUnitJson = json.units;
            this.unitCount = json.units.length;
            // this.missingMod = (this.modLoadoutUnits.find(mlu => mlu.missingMod) !== undefined);
        }
        if (json.testResult)
        {
            this.testResult = new ModLoadoutTest(json.testResult);
        }
        if (json.statDefinition)
        {
            this.statDefinition = new LoadoutDefinition(json.statDefinition);
        }
        if (json.revisions)
        {
            this.revisions = json.revisions.map((r: any) => new ModLoadoutData(r, characterMap, player));
        }

    }

    isMissingMods(): boolean
    {
        return (this.modLoadoutUnits.find(mlu => mlu.missingMod) !== undefined);
    }

    public modUsedInLoadout(modId: string): boolean
    {
        return this.modLoadoutUnits.find(mlu => mlu.modIds.includes(modId)) !== undefined;
    }

    get loadoutDate()
    {
        return this.updatedStamp !== null ? this.updatedStamp : this.createdStamp;
    }

    public static categorySorter(a: ModLoadoutData, b: ModLoadoutData): number
    {

        let aVal = a.category === null ? "" : a.category;
        let bVal = b.category === null ? "" : b.category;

        if (aVal === bVal)
        {
            aVal = a.name;
            bVal = b.name;
        }
        return aVal.localeCompare(bVal);
    }

    public static nameSorter(a: ModLoadoutData, b: ModLoadoutData): number
    {
        let aVal = a.name;
        let bVal = b.name;

        let retVal = aVal.localeCompare(bVal);
        retVal = retVal === 0 ? ModLoadoutData.categorySorter(a, b) : retVal;
        return retVal;
    }

    public static dateSorter(a: ModLoadoutData, b: ModLoadoutData): number
    {
        let aVal = a.loadoutDate === null ? "" : new Date(a.loadoutDate).toISOString().slice(0, 10);
        let bVal = b.loadoutDate === null ? "" : new Date(b.loadoutDate).toISOString().slice(0, 10);

        let retVal = bVal.localeCompare(aVal);

        retVal = retVal === 0 ? ModLoadoutData.categorySorter(a, b) : retVal;

        return retVal;
    }

    public static descriptionSorter(a: ModLoadoutData, b: ModLoadoutData): number
    {
        let aVal = a.description === null ? "" : a.description;
        let bVal = b.description === null ? "" : b.description;


        let retVal = aVal.localeCompare(bVal);
        retVal = retVal === 0 ? ModLoadoutData.categorySorter(a, b) : retVal;

        return retVal;
    }
}

export default ModLoadoutData;
